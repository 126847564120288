import type { UserImageProps } from "@circleco/ui-kit";
import { UserImage as UiKitUserImage } from "@circleco/ui-kit";
import { t } from "@/i18n-js/instance";
import EmptyAvatar from "@circle-assets/images/avatar-empty.png";

const getUserImageWithFallback = (name: string, src?: string) => {
  const isImageAndNameEmpty = !name && !src;
  const didUserSetName = name !== t("no_name");

  if (isImageAndNameEmpty || !didUserSetName) {
    return EmptyAvatar;
  }

  return src;
};

export const UserImage = ({
  hasBorder,
  isFullRounded = true,
  name,
  size,
  src,
}: UserImageProps) => {
  const imgSrc = getUserImageWithFallback(name, src);

  return (
    <UiKitUserImage
      hasBorder={hasBorder}
      isFullRounded={isFullRounded}
      name={name}
      size={size}
      src={imgSrc}
    />
  );
};
