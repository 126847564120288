import classNames from "classnames";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { SortableList } from "@circle-react-shared/uikit/SortableList";
import { Item } from "@circle-react-shared/uikit/SortableList/components/Item";
import { List } from "@circle-react-shared/uikit/SortableList/components/List";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { OptionItem } from "./OptionItem";
import { OptionPlaceholder } from "./OptionPlaceholder";
import { MAX_POLL_OPTIONS_COUNT, NEW_POLL_OPTION } from "./constants";

export const Options = ({ disabled }: { disabled?: boolean }) => {
  const { insert, fields, remove, replace } = useFieldArray({
    name: "poll_options",
  });
  const { watch } = useFormContext();
  const watchedOptions = watch("poll_options");
  const options = fields.map((field, index) => ({
    ...field,
    ...watchedOptions[index],
  }));
  const { errors } = useFormState({
    name: "poll_options",
  });

  return (
    <div className="md:pr-8">
      <SortableList
        shouldUseKeyboardSensor={false}
        items={options}
        onSort={replace}
        lockedVerticalAxis
        distance={10}
        useDragHandle
      >
        <List
          gap={2}
          className={classNames({
            "mb-2": options.length >= MAX_POLL_OPTIONS_COUNT,
          })}
        >
          {options.map((field, index) => (
            <Item
              tabIndex={-1}
              key={field.id}
              item={field}
              disabled={options.length === 1 || disabled}
              placeholder={OptionPlaceholder}
              dataTestId="poll-option-item"
            >
              <OptionItem
                key={field.id}
                index={index}
                insert={insert}
                fields={options}
                remove={remove}
                disabled={disabled}
              />
            </Item>
          ))}
          {!disabled && options.length < MAX_POLL_OPTIONS_COUNT && (
            <button
              type="button"
              tabIndex={0}
              className="hover:bg-tertiary inline-flex w-fit cursor-pointer items-center space-x-1 rounded-md p-1 pr-1.5 md:ml-8"
              onClick={() => insert(options.length, NEW_POLL_OPTION)}
            >
              <Icon type="20-plus-v2" size={20} />
              <div>
                <Typography.LabelSm weight="medium">
                  {t("tiptap.extensions.poll.add_option")}
                </Typography.LabelSm>
              </div>
            </button>
          )}
        </List>
      </SortableList>
      {errors.poll_options && (
        <p className="form-group__error pl-5 md:pl-8">
          {errors.poll_options.message?.toString()}
        </p>
      )}
    </div>
  );
};
