import type { Editor } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { EmailImageToolbar } from "./EmailImageToolbar";
import { ImageLinkWrapper } from "./ImageLinkWrapper";

export interface OriginalSizeImagePreviewProps {
  attachmentMeta: any;
  node: any;
  editor: Editor;
  updateAttributes: (attrs: any) => void;
  deleteNode: () => void;
}

export const OriginalSizeImagePreview = ({
  attachmentMeta,
  node,
  editor,
  updateAttributes,
  deleteNode,
}: OriginalSizeImagePreviewProps) => {
  const {
    attrs: { alignment, width_ratio, href },
  } = node;
  const isCentered = alignment === "center";
  const isLeft = alignment === "left";
  const isRight = alignment === "right";
  const isFullWidth = width_ratio === 1;

  const isEditable = editor.isEditable;

  return (
    <NodeViewWrapper as="div">
      <div className="group relative">
        <div
          className={classNames("flex", {
            "justify-center": isCentered,
            "justify-start": isLeft,
            "justify-end": isRight,
          })}
        >
          <div
            className={classNames("relative inline-block", {
              grow: isFullWidth,
            })}
          >
            <ImageLinkWrapper href={href} isEditable={isEditable}>
              <img
                loading="lazy"
                alt=""
                className={classNames("max-w-full", {
                  "w-full": isFullWidth,
                })}
                src={attachmentMeta.url}
              />
            </ImageLinkWrapper>
            {isEditable && (
              <EmailImageToolbar
                editor={editor}
                node={node}
                deleteNode={deleteNode}
                updateAttributes={updateAttributes}
              />
            )}
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};
