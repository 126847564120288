import { useRef } from "react";
import type { Editor } from "@tiptap/react";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { AddLinkFormBody } from "../../AddTipTapLinkModal/AddLinkForm";
import { AddLinkHeader } from "../../AddTipTapLinkModal/AddLinkHeader";
import { RemoveLinkButton } from "../../AddTipTapLinkModal/RemoveLinkButton";
import { VALIDATION_SCHEMA } from "../../AddTipTapLinkModal/validationSchema";

export interface ImageLinkModalComponentProps {
  editor: Editor;
}

export const ImageLinkModalComponent = ({
  editor,
}: ImageLinkModalComponentProps) => {
  const modal = useModal();
  const inputRef = useRef<HTMLInputElement>(null);

  const previousURL = editor.getAttributes("image").href;
  const isEditable = Boolean(previousURL);

  const onClose = modal.hide;

  const handleRemoveLink = () => {
    editor.chain().updateAttributes("image", { href: null }).run();
    void onClose();
  };

  const handleOnSubmit = ({ url }: { url: string }) => {
    editor.chain().updateAttributes("image", { href: url }).run();
    void onClose();
  };

  return (
    <Modal isOpen={modal.visible} onClose={onClose} initialFocus={inputRef}>
      <Modal.Overlay />
      <Modal.Content>
        <AddLinkHeader isEditable={isEditable} onClose={onClose} />
        <Form
          onSubmit={handleOnSubmit}
          defaultValues={{ url: previousURL }}
          validationSchema={VALIDATION_SCHEMA}
          autoComplete="off"
        >
          <AddLinkFormBody inputRef={inputRef} submitButtonVariant="circle">
            <RemoveLinkButton
              isEditable={isEditable}
              onClick={handleRemoveLink}
            />
          </AddLinkFormBody>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

const ImageLinkModal = ModalManager.create(
  ({ editor }: ImageLinkModalComponentProps) => (
    <ImageLinkModalComponent editor={editor} />
  ),
);

export const useImageLinkModal = () => useModal(ImageLinkModal);
