import { useMemo } from "react";
import { Row } from "@circle-react-uikit/SkeletonLoader/components";

interface VerticalPlanCardSkeletonProps {
  cardCount?: number;
}

export const VerticalPlanCard = ({
  cardCount = 3,
}: VerticalPlanCardSkeletonProps) => {
  const stableIds = useMemo(
    () => Array.from({ length: cardCount }, (_, index) => index),
    [cardCount],
  );

  return (
    <div className="mt-5 flex flex-col flex-wrap items-center justify-center gap-5 md:flex-row md:justify-around lg:flex-nowrap lg:justify-between">
      {stableIds.map(id => (
        <div
          key={id}
          className="border-primary flex h-[600px] w-[310px] flex-col items-start gap-y-4 rounded-2xl border px-6 py-5"
        >
          <div className="w-full">
            <Row md />
            <div className="mt-2">
              <Row xl half />
            </div>
            <div className="mt-2">
              <Row lg md />
            </div>
          </div>

          <div className="w-full">
            <Row full md />
          </div>

          <div className="w-full">
            <Row lg almostFull />
          </div>

          <div className="mt-7 flex w-full flex-col gap-y-3">
            <Row sm />
            <div className="flex flex-col gap-y-4">
              <Row almostFull sm />
              <Row almostFull sm />
              <Row almostFull sm />
              <Row almostFull sm />
              <Row almostFull sm />
              <Row almostFull sm />
              <Row almostFull sm />
              <Row almostFull sm />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
