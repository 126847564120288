import { useMemo } from "react";
import { nanoid } from "nanoid";
import { Row } from "@circle-react-uikit/SkeletonLoader/components";

interface PlanSelectorSkeletonProps {
  cardCount?: number;
}

export const PlanSelector = ({ cardCount = 4 }: PlanSelectorSkeletonProps) => {
  const stableIds = useMemo(
    () => Array.from({ length: cardCount }, () => nanoid()),
    [cardCount],
  );

  return (
    <div className="mt-4 flex w-full justify-center">
      <ul className="border-primary flex w-full flex-col overflow-hidden rounded-2xl border bg-white dark:border-zinc-700 dark:bg-zinc-800">
        {stableIds.map(id => (
          <div
            key={id}
            className="border-primary cursor-pointer border-b p-4 last:border-none sm:p-6 dark:border-zinc-700"
          >
            <div className="flex flex-row justify-between">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="flex items-start">
                    <Row square md />
                  </div>
                  <div>
                    <div className="flex flex-row items-start gap-1">
                      <Row xl long />
                      <Row badge lg />
                    </div>
                    <div className="mt-2 hidden sm:block">
                      <Row almostFull lg />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="flex flex-row items-center gap-1 align-middle">
                  <Row xl long />
                  <Row lg short />
                </div>
                <div className="mt-2 hidden sm:block">
                  <Row lg long />
                </div>
              </div>
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};
