import { NodeViewWrapper } from "@tiptap/react";
import { t } from "@/i18n-js/instance";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import { formattedMessageSentAt } from "@circle-react/helpers/dateTimeHelpers/formattedMessageSentAt";
import { useTipTapEditorContext } from "@circle-react-uikit/TipTap/index";

export const EditedLabelBlock = () => {
  const { editedAt } = useTipTapEditorContext();
  const { day, time } = formattedMessageSentAt(editedAt || new Date());

  return (
    <NodeViewWrapper as="span">
      <TippyV2 content={`${day} at ${time}`} disabled={!editedAt}>
        <span style={{ color: "#717680" }} className="ml-0.5 cursor-default">
          {t("edited")}
        </span>
      </TippyV2>
    </NodeViewWrapper>
  );
};
