import { flatten } from "lodash";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useMentions = ({ query, scope = "space", spaceId }: any) => {
  const fetchEntityItems = (page: number) =>
    reactQueryGet(
      internalApi.searchV2.advanced.index({
        params: {
          query,
          per_page: 20,
          page,
          type: "mentions",
          mention_scope: scope,
          order: "relevance",
          ...(spaceId && { space_id: spaceId }),
        },
      }),
    );

  const {
    data: { pages: items = [] } = {},
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["entities", query],
    ({ pageParam = 1 }) => fetchEntityItems(pageParam),
    {
      select: ({ pages = [] }) =>
        // RQ recommends to restructure your data that it still includes data.pages and data.pageParams properties - https://tanstack.com/query/v4/docs/react/guides/infinite-queries
        ({
          pages: flatten(pages.map(page => page.records)),
          pageParams: pages.map(page => page.page),
          pagesList: pages,
        }),
      enabled: !!query,
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  return { items, isLoading, fetchNextPage, hasNextPage };
};
