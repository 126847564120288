import type { PropsWithChildren } from "react";
import classNames from "classnames";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { useDmsV3Enabled } from "@circle-react/hooks/useDmsV3Enabled";

interface HeaderProps {
  hasNoBorder?: boolean;
  className?: string;
}
export const Header = ({
  hasNoBorder,
  children,
  className,
}: PropsWithChildren<HeaderProps>) => {
  const { isMessagingModalOpen } = useDmsV3Enabled();
  const { isChatSpace, isCourseSpace } = useRailbar();

  let minHeightStyles = "min-h-[64px]";

  if (hasNoBorder) {
    minHeightStyles = "min-h-[48px]";
  } else if (isMessagingModalOpen) {
    minHeightStyles = "min-h-[56px]";
  } else if (isChatSpace || isCourseSpace) {
    minHeightStyles = "min-h-[72px]";
  }

  return (
    <div
      className={classNames(
        "text-darkest flex items-center px-6 font-medium",
        minHeightStyles,
        {
          "border-primary border-b py-3": !hasNoBorder,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
