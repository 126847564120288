import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-shared/uikit/Button";

export function RemoveLinkButton({
  isEditable = false,
  onClick,
}: {
  isEditable?: boolean;
  onClick: () => void;
}) {
  if (!isEditable) return null;
  return (
    <div className="mt-4 flex justify-end">
      <Button full variant="secondary" type="button" onClick={onClick}>
        {t("tiptap.extensions.link.remove")}
      </Button>
    </div>
  );
}
