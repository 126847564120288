import { t } from "@/i18n-js/instance";
import type { ImageInputProps } from "@circle-react-uikit/ImageInput";
import { ImageInput } from "@circle-react-uikit/ImageInput";
import { DefaultCoverRenderer } from "./DefaultCoverRenderer";

export interface CoverImageInputProps extends ImageInputProps {}

export const CoverImageInput = ({
  renderer = DefaultCoverRenderer,
  ...rest
}: ImageInputProps) => (
  <ImageInput
    renderer={renderer}
    title={t("quick_post_v2.choose_cover_image")}
    {...rest}
  />
);
