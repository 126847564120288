import { useEffect, useState } from "react";
import { NodeViewWrapper } from "@tiptap/react";
import { t } from "@/i18n-js/instance";
import { useCurrentCommunityMember } from "@circle-react/contexts";
import { useEnterOrSpaceKeyDownHandler } from "@circle-react/hooks/useEnterOrSpaceKeyDownHandler";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { useBlockEditorContext } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { useTipTapEditorContext } from "@circle-react-uikit/TipTap/index";
import { Typography } from "@circle-react-uikit/Typography";
import { checkMessageApiAccess, hasMessageAccess } from "./helper";

export const ChatMessageDisplay = ({ node }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentCommunityMember = useCurrentCommunityMember();
  const [shouldShowAlert, toggleShouldShowAlert] = useBoolean();
  const tiptapBlockEditorContext = useBlockEditorContext();
  const tiptapEditorContext = useTipTapEditorContext();
  const { sgidToObjectMap = {}, localSgidToObjectMap = {} } =
    tiptapEditorContext || tiptapBlockEditorContext;

  const {
    addMessageAttachments,
    removeMessageAttachments,
    chatRoomAccessArr = [],
    setShouldShowPrivateMessageConfirmationOnSubmit,
    editor,
  } = tiptapEditorContext;

  const messageLink =
    localSgidToObjectMap[node.attrs.sgid] || sgidToObjectMap[node.attrs.sgid];

  const onKeyDownHander = useEnterOrSpaceKeyDownHandler(
    () => void redirectToLink(),
  );

  useEffect(() => {
    if (!messageLink) {
      setIsLoading(true);
    } else {
      addMessageAttachments(messageLink);
      if (messageLink.is_private_message) {
        setShouldShowPrivateMessageConfirmationOnSubmit(true);
      }
    }

    return () => {
      removeMessageAttachments(messageLink);
      if (messageLink?.is_private_message) {
        setShouldShowPrivateMessageConfirmationOnSubmit(false);
      }
    };
  }, [messageLink]);

  useEffect(() => {
    if (!isLoading) return;
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isLoading) {
    return (
      <NodeViewWrapper as="span">
        <span className="text-circle mx-0.5 cursor-pointer rounded-sm bg-indigo-100 p-0.5 font-medium">
          {t("tiptap.extensions.entities.loading")}
        </span>
      </NodeViewWrapper>
    );
  }

  if (!messageLink) {
    return (
      <NodeViewWrapper as="span">
        <TippyV2
          interactive={false}
          content={t("unavailable_entity_message")}
          placement="top"
        >
          <span className="text-circle mx-0.5 cursor-pointer rounded-sm bg-indigo-100 p-0.5 font-medium">
            {t("unavailable_entity")}
          </span>
        </TippyV2>
      </NodeViewWrapper>
    );
  }

  const { data: messageData, sender } = messageLink;

  const redirectToLink = async () => {
    const hasAccessArray = chatRoomAccessArr.length > 0;
    const shouldCheckApiAccess = !editor.isEditable && !hasAccessArray;
    const isMessageOfCurrentCommunityMember =
      sender.community_member_id === currentCommunityMember?.id;

    const checkMessageAccess = () =>
      editor.isEditable
        ? true // Message yet to be created
        : hasMessageAccess(messageLink.id, chatRoomAccessArr);

    const checkApiAccess = () =>
      checkMessageApiAccess({
        chatRoomUuid: messageData.chat_room_uuid,
        messageId: messageLink.id,
      });

    const hasAccess = isMessageOfCurrentCommunityMember
      ? true
      : shouldCheckApiAccess
        ? await checkApiAccess()
        : checkMessageAccess();

    if (hasAccess) {
      window.open(messageData.url, "_blank", "noreferrer");
    } else {
      toggleShouldShowAlert();
    }
  };

  return (
    <>
      <NodeViewWrapper as="span">
        <TippyV2 interactive={false} content={messageData.url} placement="top">
          {/* eslint-disable-next-line jsx-a11y/prefer-tag-over-role -- This component can be a child of a <s> tag */}
          <span
            id={`message-link-${messageData.id}`}
            contentEditable={false}
            className="text-circle mx-0.5 cursor-pointer rounded-sm bg-indigo-100 p-0.5 font-medium"
            onClick={redirectToLink}
            onKeyDown={onKeyDownHander}
            tabIndex={0}
            role="button"
          >
            {messageData.label}
          </span>
        </TippyV2>
      </NodeViewWrapper>
      <ConfirmationModal
        isOpen={shouldShowAlert}
        title={t("messaging.actions.unable_to_open_link.title")}
        onConfirm={toggleShouldShowAlert}
        confirmVariant="danger"
        confirmText={t("close")}
      >
        <Typography.BodyMd>
          {t("messaging.actions.unable_to_open_link.description")}
        </Typography.BodyMd>
      </ConfirmationModal>
    </>
  );
};
