import classNames from "classnames";
import { times, uniqueId } from "lodash";
import { Row } from "@circle-react-uikit/SkeletonLoader/components";

export interface CommunitySwitcherProps {
  count?: number;
}

export const CommunitySwitcherSkeleton = ({
  count = 5,
}: CommunitySwitcherProps) => (
  <div
    data-testid="community-switcher-skeleton"
    className={classNames(
      "border-primary bg-primary flex h-screen w-full max-w-[4.5rem] flex-col items-center gap-4 overflow-y-auto border-r p-4",
    )}
  >
    {times(count, () => (
      <Row key={uniqueId()} squareExtraLarge />
    ))}
  </div>
);
