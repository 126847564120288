import type { Editor } from "@tiptap/react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { useCommunityFeatureFlagsContext } from "@circle-react/contexts";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Button } from "@circle-react-shared/uikit/Button";
import { EmojiPickerV3 } from "@circle-react-shared/uikit/EmojiPickerV3";
import { getPortalTarget } from "@circle-react-shared/uikit/TipTap/utilities/getPortalTarget";
import { VoiceMessage } from "../VoiceMessage";
import { isMessageBoxActive } from "../utilities";
import { GifPopover } from "./GifPopover";
import { MenuIconButton } from "./MenuIconButton";

export interface MenuBarProps {
  editor: Editor;
  handleSubmit: () => void;
  openFilePicker: () => void;
  isUploading: boolean;
  editorHasNoContent: () => boolean;
  isFocused: boolean;
  isSubmitting: boolean;
  isUpdate: boolean;
  isLiveStream?: boolean;
  submitButtonLabel?: string;
  submittingLabel?: string;
  portalTargetId: string;
}

export const MenuBar = ({
  editor,
  handleSubmit,
  openFilePicker,
  isUploading,
  editorHasNoContent,
  isFocused,
  isSubmitting,
  isUpdate,
  isLiveStream = false,
  submitButtonLabel,
  submittingLabel,
  portalTargetId,
}: MenuBarProps) => {
  const {
    record: { voice_messages_enabled: isVoiceMessagesEnabled },
  } = useCommunityFeatureFlagsContext();

  const appendTo = portalTargetId ? getPortalTarget(portalTargetId) : null;
  const addMention = () => {
    editor.chain().focus().insertContent(" @").run();
  };

  const addEntity = () => {
    editor.chain().focus().insertContent(" #").run();
  };

  const isLightButton =
    isFocused || isMessageBoxActive() || !editorHasNoContent();

  return (
    <div
      className={classNames(
        "flex items-end justify-between",
        submitButtonLabel ? "mt-3" : "mt-4",
      )}
    >
      <div className="flex gap-1">
        <TippyV2
          placement="bottom"
          interactive={false}
          content={t("tiptap.add_image")}
          dataTestId="add_image_message"
        >
          <MenuIconButton
            onClick={openFilePicker}
            aria-label={t("tiptap.add_image")}
            icon="20-image-2"
            isLight={isLightButton}
          />
        </TippyV2>
        <EmojiPickerV3
          portalTo={appendTo}
          placement="top-start"
          onEmojiSelect={emojiData =>
            editor
              .chain()
              .focus()
              .insertContent(emojiData.emoji)
              .insertContent(" ")
              .run()
          }
          targetWrapperClassName="!flex"
          target={
            <TippyV2
              placement="bottom"
              interactive={false}
              content={t("tiptap.add_emoji")}
              dataTestId="add_emoji_message"
            >
              <MenuIconButton
                aria-label={t("tiptap.add_image")}
                icon="20-reaction"
                isLight={isLightButton}
              />
            </TippyV2>
          }
        />
        <TippyV2
          placement="bottom"
          interactive={false}
          content={t("tiptap.add_entity")}
          dataTestId="add_entity_list"
        >
          <MenuIconButton
            onClick={addEntity}
            aria-label={t("add_entity")}
            icon="20-hash"
            isLight={isLightButton}
          />
        </TippyV2>
        <TippyV2
          placement="bottom"
          interactive={false}
          content={t("tiptap.add_mention")}
          dataTestId="add_mention_message"
        >
          <MenuIconButton
            onClick={addMention}
            aria-label={t("add_mention")}
            icon="20-mention"
            isLight={isLightButton}
          />
        </TippyV2>
        <GifPopover isLight={isLightButton} />
        <TippyV2
          placement="bottom"
          interactive={false}
          content={t("tiptap.attach_file")}
          dataTestId="add_attachment_message"
        >
          <MenuIconButton
            onClick={openFilePicker}
            aria-label={t("tiptap.attach_file")}
            icon="20-attachment"
            isLight={isLightButton}
          />
        </TippyV2>
        {isVoiceMessagesEnabled && !isLiveStream && !isUpdate && (
          <VoiceMessage isLight={isLightButton} />
        )}
      </div>
      {submitButtonLabel ? (
        <Button
          type="submit"
          variant="circle"
          onClick={handleSubmit}
          disabled={isSubmitting || editorHasNoContent() || isUploading}
          small
          className="!px-4"
        >
          {isSubmitting
            ? submittingLabel || submitButtonLabel
            : submitButtonLabel}
        </Button>
      ) : (
        <TippyV2
          placement="bottom"
          interactive={false}
          content={t("chat_space.send_message")}
          dataTestId="submit_button_message"
        >
          <MenuIconButton
            type="submit"
            onClick={handleSubmit}
            disabled={isSubmitting || editorHasNoContent() || isUploading}
            id="tiptap-send"
            aria-label={t("chat_space.send_message")}
            icon={isUploading || isSubmitting ? "loader" : "28-message-send"}
            size={isUploading || isSubmitting ? 20 : 28}
            isLight={!editorHasNoContent()}
            shouldAnimateSpin={isUploading || isSubmitting}
            isSendButton
          />
        </TippyV2>
      )}
    </div>
  );
};
