import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-shared/uikit/Button";
import { Form } from "@circle-react-shared/uikit/Form";
import { getEmbedValidationSchema } from "@circle-react-shared/uikit/TipTapBlockEditor/EmbedExtension/constants";
import { useCreateEmbed } from "@circle-react-shared/uikit/TipTapBlockEditor/EmbedExtension/useCreateEmbed";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const EmbedForm = ({
  addToLocalSgidToObjectMap,
  editor,
  range,
  inputRef,
  provider,
}: any) => {
  const createEmbedMutation = useCreateEmbed({
    addToLocalSgidToObjectMap,
    editor,
    range,
  });

  const handleOnSubmit = ({ url }: any) => createEmbedMutation.mutate(url);
  return (
    <Form
      onSubmit={handleOnSubmit}
      defaultValues={{ url: "" }}
      validationSchema={getEmbedValidationSchema({
        allowUnsupportedURLs: true,
      })}
      autoComplete="off"
    >
      <div
        className="mb-6 flex h-full flex-col items-center rounded-md"
        role="tabpanel"
      >
        <Form.Item
          name="url"
          fullWidth
          hideBorder
          hideLabel
          hideDescription
          className="!pt-0"
          placeholder={t("courses.edit.lesson.embed_placeholder")}
        >
          <Form.Input ref={inputRef} />
        </Form.Item>
        <div className="mt-4 text-center">
          <Button
            disabled={createEmbedMutation.isLoading}
            type="submit"
            variant="circle"
          >
            {createEmbedMutation.isLoading
              ? t("courses.edit.lesson.embedding")
              : t("courses.edit.lesson.embed_media")}
          </Button>
          <div className="mt-2">
            {provider ? (
              <Typography.LabelSm weight="semibold">
                {t("tiptap.extensions.embed.provider_description.works_with", {
                  provider,
                })}
                <a
                  href="https://iframely.com/domains"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("tiptap.extensions.embed.provider_description.providers")}
                </a>
              </Typography.LabelSm>
            ) : (
              <Typography.LabelSm weight="semibold">
                {t("tiptap.extensions.embed.description.start")}
                <a
                  href="https://iframely.com/domains"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("tiptap.extensions.embed.description.providers")}
                </a>
                {t("tiptap.extensions.embed.description.end")}
              </Typography.LabelSm>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
};
