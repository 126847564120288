import PropTypes from "prop-types";
import { noop } from "lodash";
import { t } from "@/i18n-js/instance";

export const Search = ({ onChange = noop }) => (
  <input
    type="search"
    placeholder={t("media_player.search")}
    className="shadow-xs placeholder:text-light w-full rounded-lg border-gray-300 placeholder:opacity-100"
    onChange={onChange}
  />
);

Search.propTypes = {
  onChange: PropTypes.func,
};
