import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { truncate } from "lodash";
import { get } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

const FileUploadComponent = ({ name, errorMessage, onChange }) => {
  const { error } = useToast();
  const [fileName, setFileName] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (errorMessage) {
      error(errorMessage);
    }
  }, [errorMessage]);

  const handleOnChange = e => {
    const file = e.target.files[0];
    // Read as data url
    const reader = new FileReader();
    reader.onload = () => {
      onChange(reader.result);
    };
    reader.readAsDataURL(file);
    setFileName(file.name);
  };

  const handleRemoveFile = e => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current.value = null;
    onChange(null);
    setFileName(null);
  };

  return (
    <div>
      <label
        className={classNames(
          "bg-primary flex cursor-pointer rounded-md border border-gray-600 px-2 py-1.5 text-sm",
          {
            "justify-center": !fileName,
            "hover:bg-selector-active": !fileName,
            "border-red-500": errorMessage,
          },
        )}
      >
        {!fileName && (
          <span>{t("media_player.transcript_menu.file_type.user_file")}</span>
        )}
        {fileName && (
          <span className="flex gap-2">
            <Icon type="20-attach" size={20} className="shrink-0" />
            <span className="grow">
              {truncate(fileName, { length: 17, omission: "..." })}
            </span>
            <Icon
              type="16-close"
              size={16}
              onClick={handleRemoveFile}
              className="shrink-0"
            />
          </span>
        )}
        <input
          type="file"
          name={name}
          onChange={handleOnChange}
          className="hidden"
          itemType="text/vtt"
          accept=".vtt"
          ref={inputRef}
        />
      </label>
    </div>
  );
};

export const FileUpload = ({ name, rules, ...passThroughProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, `${name}.message`);
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <FileUploadComponent
          {...passThroughProps}
          onChange={onChange}
          value={value}
          errorMessage={errorMessage}
        />
      )}
    />
  );
};
