import { forwardRef } from "react";
import type { ComponentPropsWithRef } from "react";
import classNames from "classnames";
import { Icon } from "@circle-react/components/shared/Icon";
import type { IconType } from "@circle-react/components/shared/Icon";

export interface MenuIconButtonProps extends ComponentPropsWithRef<"button"> {
  icon: IconType;
  ["aria-label"]: string;
  isLight?: boolean;
  shouldAnimateSpin?: boolean;
  size?: number;
  isSendButton?: boolean;
}

export const MenuIconButton = forwardRef<
  HTMLButtonElement,
  MenuIconButtonProps
>(
  (
    {
      icon,
      isLight,
      shouldAnimateSpin,
      className,
      disabled,
      size = 20,
      isSendButton,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      type="button"
      className={classNames(
        "flex h-7 w-7 items-center justify-center rounded",
        {
          "hover:bg-tertiary focus-visible:bg-tertiary p-1": !isSendButton,
          "cursor-not-allowed": disabled,
        },
        className,
      )}
      disabled={disabled}
      {...props}
    >
      <Icon
        type={icon}
        size={size}
        className={classNames(size === 28 ? "h-7 !w-7" : "h-5 w-5", {
          "text-default dark:text-dark": isLight,
          "text-light dark:text-default": !isLight,
          "animate-spin": shouldAnimateSpin,
          "!text-brand": isSendButton && isLight,
          "!text-disabled": isSendButton && !isLight,
        })}
      />
    </button>
  ),
);

MenuIconButton.displayName = "MenuIconButton";
