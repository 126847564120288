import { t } from "@/i18n-js/instance";
import { Modal } from "@circle-react-uikit/ModalV2";

export function AddLinkHeader({
  isEditable,
  onClose,
}: {
  isEditable: boolean;
  onClose: () => void;
}) {
  return (
    <Modal.Header>
      <Modal.Title className="text-center" size="md">
        {isEditable
          ? t("tiptap.extensions.link.edit")
          : t("tiptap.extensions.link.add")}
      </Modal.Title>
      <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
        <Modal.CloseButton onClick={onClose} />
      </Modal.HeaderActions>
    </Modal.Header>
  );
}
