import classNames from "classnames";
import I18n from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { CircularProgress } from "./CircularProgress";

export interface CancelButtonWithProgressProps {
  queuedForCancellation: any;
  removeCurrentAttachment: () => void;
  progress: number;
  isUploading: boolean;
}

export const CancelButtonWithProgress = ({
  queuedForCancellation,
  removeCurrentAttachment,
  progress,
  isUploading,
}: CancelButtonWithProgressProps) => (
  <button
    type="button"
    onClick={e => {
      queuedForCancellation.current = true;
      removeCurrentAttachment();
      e.preventDefault();
    }}
    className="border-primary bg-tertiary group absolute right-0 top-0 -m-2 flex h-[22px] w-[22px] cursor-pointer items-center justify-center rounded-full border"
  >
    {isUploading && (
      <div className="flex items-center group-hover:hidden">
        <CircularProgress percentage={progress} size={14} strokeWidth={2} />
      </div>
    )}
    <TippyV2 content={I18n.t("tiptap.remove_attachment")}>
      <div
        className={classNames({
          "hidden group-hover:block": isUploading,
        })}
      >
        <Icon type="close" />
      </div>
    </TippyV2>
  </button>
);
