import type { UseMutationOptions, UseQueryOptions } from "react-query";
import { useMutation, useQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import type { ApiError } from "@/react/config/CustomErrors";
import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { PollResponse } from "@circle-react-shared/uikit/TipTapBlockEditor/PollExtension/PollModal/api";
import { useToast } from "@circle-react-uikit/ToastV2";

export interface UsePollProps {
  sgid?: string;
  options?: UseQueryOptions<PollResponse>;
}

export const usePoll = ({ sgid, options = {} }: UsePollProps) =>
  useQuery({
    queryKey: ["poll", sgid],
    queryFn: () => {
      if (!sgid) {
        throw new Error("sgid is required");
      }
      return reactQueryGet(internalApi.polls.showBySgid(sgid));
    },
    enabled: !!sgid,
    ...options,
  });

export const useUndoMutation = ({
  options = {},
}: {
  options?: UseMutationOptions<any, unknown, any>;
} = {}) =>
  useMutation(
    (pollId: number) => reactQueryDelete(internalApi.polls.undo(pollId)),
    options,
  );

export const useExportPollResponses = () => {
  const toast = useToast();

  const { mutate: handleExportPollResponses, isLoading: isExporting } =
    useMutation<{ success: boolean; email?: string }, ApiError, number>({
      mutationFn: (pollId: number) =>
        reactQueryPost(internalApi.polls.exportCsv(pollId)),
      onSuccess: data => {
        toast.success(t("export_member_csv.message", { email: data.email }));
      },
      onError: error => {
        toast.error(error.message);
      },
    });

  return { handleExportPollResponses, isExporting };
};
