import PropTypes from "prop-types";
import classnames from "classnames";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";

export const ClearButton = ({ children, onClick, onButtonClick }) => (
  <div className="flex items-center">
    <Button
      variant="secondary"
      className={classnames(
        "rounded-r-none border-r-0 !pl-3 !pr-2 !font-light",
        {
          "pointer-events-none": !onButtonClick,
        },
      )}
      onClick={onButtonClick}
    >
      {children}
    </Button>
    <div className="ml-[-1px]">
      <Button
        onClick={onClick}
        variant="secondary"
        className="rounded-l-none border-l-0 !px-1.5"
        data-testid="clear-action-button"
        ariaLabel={t("clear")}
        title={t("clear")}
      >
        <Icon aria-hidden type="16-close" size={16} className="mb-[1px]" />
      </Button>
    </div>
  </div>
);

ClearButton.propTypes = {
  onClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  children: PropTypes.any,
};
