import { useEffect, useRef } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const EditOptions = ({
  options,
  onClick,
  activeIndex,
  setActiveIndex,
  shouldShowEnterIcon,
}) => {
  const activeItemRef = useRef(null);

  useEffect(() => {
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [activeIndex]);

  return (
    <div className="bg-primary text-dark border-primary absolute top-full mt-1 max-h-80 w-80 overflow-auto rounded-lg border p-2 shadow-xl">
      <div className="px-4 py-0.5">
        <Typography.LabelXxs color="text-light" weight="semibold">
          {t("tiptap.extensions.ai_copilot.prompt_options.title")}
        </Typography.LabelXxs>
      </div>
      {options.map((item, index) => {
        const onMouseOverOrFocus = () => setActiveIndex(index);

        return (
          <button
            ref={index === activeIndex ? activeItemRef : null}
            type="button"
            key={item.label}
            onMouseOver={onMouseOverOrFocus}
            onFocus={onMouseOverOrFocus}
            className={classNames(
              "text-default hover:text-dark flex w-full items-center gap-2 rounded-md px-4 py-2 text-left text-sm font-normal transition-colors",
              { "bg-secondary": index === activeIndex },
            )}
            onClick={() => onClick({ item, index })}
          >
            <span className="flex items-center">
              <Icon type="16-thunder" className="!h-4 !w-4" size={16} />
            </span>
            <span className="text-dark">{item.label}</span>
            {index === activeIndex && shouldShowEnterIcon && (
              <span className="ml-auto flex items-center">
                <Icon type="16-arrow-enter" className="!h-4 !w-4" size={16} />
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
};
