import { t } from "@/i18n-js/instance";

export const thirdPartyEmbeds = [
  {
    name: "youtube",
    title: t("tiptap.slash_commands.youtube"),
  },
  {
    name: "wistia",
    title: t("tiptap.slash_commands.wistia"),
  },
  {
    name: "vimeo",
    title: t("tiptap.slash_commands.vimeo"),
  },
  {
    name: "loom",
    title: t("tiptap.slash_commands.loom"),
  },
  {
    name: "soundcloud",
    title: t("tiptap.slash_commands.soundcloud"),
  },
  {
    name: "spotify",
    title: t("tiptap.slash_commands.spotify"),
  },
  {
    name: "simplecast",
    title: t("tiptap.slash_commands.simplecast"),
  },
  {
    name: "instagram",
    title: t("tiptap.slash_commands.instagram"),
  },
  {
    name: "facebook",
    title: t("tiptap.slash_commands.facebook"),
  },
  {
    name: "twitter",
    title: t("tiptap.slash_commands.twitter"),
  },
  {
    name: "typeform",
    title: t("tiptap.slash_commands.typeform"),
  },
  {
    name: "airtable",
    title: t("tiptap.slash_commands.airtable"),
  },
  {
    name: "github",
    title: t("tiptap.slash_commands.github"),
  },
  {
    name: "google-maps",
    title: t("tiptap.slash_commands.google_maps"),
  },
  {
    name: "codepen",
    title: t("tiptap.slash_commands.codepen"),
  },
] as const;
