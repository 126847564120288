export function ImageLinkWrapper({
  href,
  isEditable = false,
  children,
}: {
  href?: string;
  isEditable?: boolean;
  children: React.ReactNode;
}) {
  if (!href || isEditable) return <>{children}</>;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}
