import { useEffect, useLayoutEffect, useRef, useState } from "react";
import type { ReactNode } from "react";
import classNames from "classnames";
import { defer } from "lodash";
import scrollIntoView from "scroll-into-view-if-needed";
import { t } from "@/i18n-js/instance";
import { isIphone, isSafari } from "@circle-react/helpers/browserHelpers";
import { lineClampClasses, safariLineClampClasses } from "./constants";
import type { LineClampLevel } from "./constants";

export interface SeeMoreLessProps {
  children: ReactNode;
  disabled?: boolean;
  hideToggle?: boolean;
  lines?: LineClampLevel;
  onToggle?: (isExpanded: boolean) => void;
  seeMoreClassName?: string;
}

export type { LineClampLevel };

export const SeeMoreLess = ({
  children,
  disabled = false,
  hideToggle = false,
  lines = 2,
  onToggle,
  seeMoreClassName,
}: SeeMoreLessProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShowingExpandControl, setIsShowingExpandControl] =
    useState(!disabled);
  const [hasTouchedToggle, setHasTouchedToggle] = useState(false);

  const clampClasses: string =
    isSafari() || isIphone()
      ? safariLineClampClasses[lines]
      : lineClampClasses[lines];

  const toggleExpand = () => {
    if (disabled) return;
    setHasTouchedToggle(true);
    setIsExpanded(previous => !previous);
  };

  useEffect(() => {
    if (disabled || !isShowingExpandControl) return;

    if (onToggle) {
      onToggle(isExpanded);
    }
  }, [disabled, isExpanded, isShowingExpandControl, onToggle]);

  useEffect(() => {
    if (
      isExpanded ||
      !buttonRef.current ||
      !isShowingExpandControl ||
      !hasTouchedToggle
    ) {
      return;
    }

    const button: HTMLButtonElement = buttonRef.current;

    // Wen collapsing content, scroll the toggle into view.
    // There's a delay because it takes some time for the dom to collapse.
    const timer = setTimeout(() => {
      scrollIntoView(button, {
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
        behavior: "smooth",
      });
    }, 100);

    return () => clearTimeout(timer);
  }, [buttonRef, hasTouchedToggle, isExpanded, isShowingExpandControl]);

  // Don't show the expand control if the content fits in the container.
  useLayoutEffect(() => {
    const conditionallyShowExpandControl = () => {
      if (!contentRef.current || disabled) return;
      const contentElement: HTMLDivElement = contentRef.current;

      requestAnimationFrame(() => {
        const { scrollHeight, offsetHeight } = contentElement;
        const isOverflowing = scrollHeight > offsetHeight;
        setIsShowingExpandControl(isOverflowing);
      });
    };

    const timer = defer(conditionallyShowExpandControl);
    return () => clearTimeout(timer);
  }, [disabled, contentRef]);

  const shouldHideToggle = !isShowingExpandControl || disabled || hideToggle;
  const shouldExpandContent = isExpanded || disabled || !isShowingExpandControl;

  return (
    <div className="flex flex-col" data-testid="see-more-less">
      <div
        ref={contentRef}
        className={classNames({
          "line-clamp-none h-auto": shouldExpandContent,
          [clampClasses]: !shouldExpandContent,
        })}
        data-testid="see-more-less-content"
      >
        {children}
      </div>
      <button
        type="button"
        ref={buttonRef}
        data-testid="show-expand"
        onClick={toggleExpand}
        className={classNames(
          "text-light hover:text-dark focus-visible:text-dark inline-flex text-base",
          {
            hidden: shouldHideToggle,
          },
          seeMoreClassName,
        )}
      >
        {isExpanded ? t("show_less") : t("show_more")}
      </button>
    </div>
  );
};
