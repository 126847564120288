import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";

interface BadgeV3Props {
  label: string;
  isAdmin?: boolean;
}

export const BadgeV3 = ({ label, isAdmin = false }: BadgeV3Props) => (
  <div
    className={classNames(
      "text-tertiary flex h-[22px] max-w-fit items-center rounded-xl px-2 text-[10px] font-bold uppercase tracking-wide",
      isAdmin
        ? "group-hover:bg-overlay-dark bg-[#ECF5FF] dark:bg-[#34373D] dark:group-hover:bg-[#202226]"
        : "bg-tertiary group-hover:bg-overlay-dark dark:group-hover:bg-[#202226]",
    )}
    aria-label={label}
  >
    <Icon
      type="14-moderator-icon"
      size={14}
      className={classNames(
        "mr-0.5 !w-4",
        isAdmin ? "text-[#2563EB]" : "text-tertiary",
      )}
    />
    {label}
  </div>
);
