import { useBlockEditorContext } from "..";
import { NodeViewWrapper } from "@tiptap/react";
import { useQuery } from "react-query";
import { useAttachmentGallery } from "@circle-react/components/Modals/AttachmentGalleryModal";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { supportsGalleryPreview } from "@circle-react-shared/uikit/TipTap/utilities/contentTypeParser";
import { OriginalSizeImagePreview } from "./EmailImage/OriginalSizeImagePreview";
import { ImageContainer } from "./ImageContainer";

export interface ImagePreviewProps {
  editor: any;
  node: any;
  updateAttributes: (attrs: any) => void;
  deleteNode: () => void;
}

export const ImagePreview = ({
  editor,
  node,
  updateAttributes,
  deleteNode,
}: ImagePreviewProps) => {
  const { show: openAttachmentGallery } = useAttachmentGallery();
  const isEditable = editor.isEditable;
  const {
    localInlineAttachments,
    inlineAttachments,
    addToLocalInlineAttachments,
    isWithinEmailEditor,
  } = useBlockEditorContext() ?? {};

  const signedId = node.attrs?.signed_id;
  const attachmentMeta =
    localInlineAttachments?.find(
      attachment => attachment.signed_id === signedId,
    ) ||
    inlineAttachments?.find(attachment => attachment.signed_id === signedId);

  const attachmentsForGalleryPreview = inlineAttachments?.filter(a =>
    supportsGalleryPreview(a.content_type),
  );

  const { isLoading } = useQuery(
    internalApi.richTextFields.fetchAttachment({ signedId }),
    {
      enabled: !attachmentMeta && isEditable,
      retry: false,
      retryOnMount: false,
      onSuccess: addToLocalInlineAttachments,
    },
  );

  const onClickOpenAttachmentGallery = () => {
    if (isEditable) return;
    void openAttachmentGallery({
      attachments: attachmentsForGalleryPreview,
      startIndex: attachmentsForGalleryPreview?.findIndex(
        a => a.signed_id == attachmentMeta.signed_id,
      ),
    });
  };

  if (isLoading) {
    return (
      <NodeViewWrapper as="div" className="my-4">
        <Icon type="loader" size={16} />
      </NodeViewWrapper>
    );
  }

  if (!attachmentMeta) return null;

  if (
    isWithinEmailEditor &&
    (node.attrs.use_intrinsic_size || node.attrs.href)
  ) {
    return (
      <NodeViewWrapper as="div">
        <OriginalSizeImagePreview
          node={node}
          editor={editor}
          attachmentMeta={attachmentMeta}
          updateAttributes={updateAttributes}
          deleteNode={deleteNode}
        />
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper as="div">
      <ImageContainer
        node={node}
        editor={editor}
        attachmentMeta={attachmentMeta}
        onClickOpenAttachmentGallery={onClickOpenAttachmentGallery}
        updateAttributes={updateAttributes}
        deleteNode={deleteNode}
      />
    </NodeViewWrapper>
  );
};
