import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ImagePickerModalV2 } from "../../ImagePickerModalV2";

export const ImagePickerModalComponent = ({
  editor,
  range,
  addToLocalInlineAttachments,
  isWithinEmailEditor,
}: any) => {
  const drawer = useModal();
  const createImageNode = ({ url, signed_id, content_type }: any) => {
    addToLocalInlineAttachments({ url, signed_id, content_type });
    let imageAttrs = {
      url,
      signed_id,
      content_type,
      width_ratio: 1,
      alignment: "center",
      use_intrinsic_size: false,
    };
    if (isWithinEmailEditor) {
      imageAttrs = {
        url,
        signed_id,
        content_type,
        width_ratio: 0,
        alignment: "left",
        use_intrinsic_size: true,
      };
    }
    editor
      .chain()
      .focus()
      .insertContentAt(range, [
        {
          type: "image",
          attrs: imageAttrs,
        },
        {
          type: "hardBreak",
        },
      ])
      .run();
    drawer.remove();
  };

  return (
    <ImagePickerModalV2
      onComplete={createImageNode}
      isOpen={drawer.visible}
      onClose={drawer.remove}
    />
  );
};

const ImagePickerModal = ModalManager.create(
  ({
    editor,
    range,
    addToLocalInlineAttachments,
    isWithinEmailEditor,
  }: any) => (
    <ImagePickerModalComponent
      editor={editor}
      range={range}
      addToLocalInlineAttachments={addToLocalInlineAttachments}
      isWithinEmailEditor={isWithinEmailEditor}
    />
  ),
);

export const useImagePickerModal = () => useModal(ImagePickerModal);
