import PropTypes from "prop-types";
import { BubbleMenu } from "@tiptap/react";
import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { useUsageContext } from "@circle-react/providers";
import { TextModifierButton } from "@circle-react-shared/uikit/TipTap/Extensions/TextModifiers/TextModifierButton";
import { shouldShowToolbar } from "@circle-react-shared/uikit/TipTap/Extensions/TextModifiers/utilities";
import { useAddTipTapLinkModal } from "@circle-react-uikit/TipTapBlockEditor/AddTipTapLinkModal";
import { AiCopilotButton } from "./AiCopilotButton";
import "./styles.scss";

export const TextModifiersMenu = ({
  editor,
  shouldShowCopilot = false,
  showListsInTextModifierMenu = false,
  placement = "top",
  headingLevels,
}) => {
  const useAddTipTapLink = useAddTipTapLinkModal();
  const { isAdminUsageContext } = useUsageContext();

  const options = [
    {
      name: "bold",
      iconType: "20-character-bold",
      label: t("tiptap.bubble_menu.bold"),
      onClick: () => editor.chain().focus().toggleBold().run(),
    },
    {
      name: "italic",
      iconType: "20-character-italic",
      label: t("tiptap.bubble_menu.italic"),
      onClick: () => editor.chain().focus().toggleItalic().run(),
    },
    {
      name: "h1",
      iconType: "16-heading1",
      label: t("tiptap.bubble_menu.h1"),
      onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      isActiveFn: () => editor.isActive("heading", { level: 1 }),
    },
    {
      name: "h2",
      iconType: "20-character-h2",
      label: t("tiptap.bubble_menu.h2"),
      onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActiveFn: () => editor.isActive("heading", { level: 2 }),
    },
    {
      name: "h3",
      iconType: "20-character-h3",
      label: t("tiptap.bubble_menu.h3"),
      onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      isActiveFn: () => editor.isActive("heading", { level: 3 }),
    },
    {
      name: "strike",
      iconType: "20-character-strike",
      label: t("tiptap.bubble_menu.strike"),
      onClick: () => editor.chain().focus().toggleStrike().run(),
    },
    {
      name: "underline",
      iconType: "20-character-underline",
      label: t("tiptap.bubble_menu.underline"),
      onClick: () => editor.chain().focus().toggleUnderline().run(),
    },
    {
      name: "link",
      iconType: "20-character-link",
      label: t("tiptap.bubble_menu.link"),
      onClick: () => useAddTipTapLink.show({ editor, isAdminUsageContext }),
    },
    {
      name: "bullet_list",
      iconType: "16-editor-ul",
      label: t("tiptap.bubble_menu.bullet_list"),
      onClick: () => editor.chain().focus().toggleBulletList().run(),
    },
    {
      name: "ordered_list",
      iconType: "16-editor-ol",
      label: t("tiptap.bubble_menu.ordered_list"),
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
    },
  ].filter(command => {
    if (command.name === "h1" && !headingLevels.includes(1)) return false;
    if (command.name === "h2" && !headingLevels.includes(2)) return false;
    if (command.name === "bullet_list" && !showListsInTextModifierMenu)
      return false;
    if (command.name === "ordered_list" && !showListsInTextModifierMenu)
      return false;
    return !(command.name === "h3" && !headingLevels.includes(3));
  });

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        duration: 100,
        arrow: false,
        placement,
        theme: "text-modifiers",
        animation: "shift-away-subtle",
        maxWidth: "none",
      }}
      shouldShow={shouldShowToolbar}
    >
      <div className="bg-primary border-primary divide-primary flex gap-1 divide-x rounded-lg border p-1 shadow-sm">
        {shouldShowCopilot && <AiCopilotButton editor={editor} />}
        <div>
          {options.map(option => (
            <TextModifierButton
              key={option.name}
              isActive={
                isFunction(option.isActiveFn)
                  ? option.isActiveFn()
                  : editor.isActive(option.name)
              }
              {...option}
            />
          ))}
        </div>
      </div>
    </BubbleMenu>
  );
};

TextModifiersMenu.propTypes = {
  editor: PropTypes.object.isRequired,
};
