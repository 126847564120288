import PropTypes from "prop-types";
import I18n from "@/i18n-js/instance";

export const SearchBox = ({ query, setQuery }) => (
  <div className="p-2 pb-0">
    <input
      type="text"
      placeholder={I18n.t("tiptap.search_giphy")}
      onChange={event => setQuery(event.target.value)}
      value={query}
      className="bg-secondary placeholder:text-light w-full rounded border-none text-sm placeholder:opacity-100 focus:border-none focus:shadow-none focus:ring-0"
    />
  </div>
);

SearchBox.propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func,
};
