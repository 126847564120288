import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { DateTimePicker } from "@circle-react-shared/uikit/DateTimePicker";

export const PollDuration = () => {
  const { watch } = useFormContext();
  const pollDurationEnabled = watch("enable_poll_duration");
  if (!pollDurationEnabled) {
    return null;
  }
  return (
    <div className="mt-2">
      <DateTimePicker
        datePlaceholder={t(
          "tiptap.extensions.poll.settings.cutoff_date_placeholder",
        )}
        timePlaceholder={t(
          "tiptap.extensions.poll.settings.cutoff_time_placeholder",
        )}
        name="closes_at"
        minDate={new Date()}
      />
    </div>
  );
};
