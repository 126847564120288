import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { MenuIconButton } from "../MenuBar/MenuIconButton";
import { Recorder } from "./components";

export const VoiceMessage = ({ isLight }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  return (
    <div>
      <TippyV2
        placement="bottom"
        interactive={false}
        content={t("tiptap.voice_message.action")}
        dataTestId="add_voice_message"
      >
        <MenuIconButton
          type="submit"
          onClick={onOpen}
          aria-label={t("tiptap.voice_message.action")}
          icon="20-mic-2"
          isLight={isLight}
          iconClassName="relative top-[2px] left-[2px]"
        />
      </TippyV2>
      {isOpen && <Recorder onClose={onClose} />}
    </div>
  );
};
