import { t } from "@/i18n-js/instance";
import { Prompt } from "../../Prompt";
import { useKeyboardNavigation } from "../../hooks/useKeyboardNavigation";

const OPTIONS = [
  {
    label: t(
      "tiptap.extensions.ai_copilot.prompt_options.completions.suggest_ideas.label",
    ),
    value: "suggest_ideas",
  },
  {
    label: t(
      "tiptap.extensions.ai_copilot.prompt_options.completions.outline.label",
    ),
    value: "outline",
  },
  {
    label: t(
      "tiptap.extensions.ai_copilot.prompt_options.completions.paragraph.label",
    ),
    value: "paragraph",
  },
  {
    label: t(
      "tiptap.extensions.ai_copilot.prompt_options.completions.conclusion.label",
    ),
    value: "conclusion",
  },
];

export const EditOptions = ({ onClick }) => {
  const { activeIndex, setActiveIndex } = useKeyboardNavigation({
    optionsCount: OPTIONS.length,
    onEnter: index => {
      onClick(
        OPTIONS[index].value,
        t(
          `tiptap.extensions.ai_copilot.prompt_options.completions.${OPTIONS[index].value}.prompt_prefix`,
        ),
      );
    },
  });

  return (
    <Prompt.EditOptions
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      onClick={({ item }) => onClick(item.value, item.label)}
      options={OPTIONS}
      shouldShowEnterIcon
    />
  );
};
