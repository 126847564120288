import { t } from "@/i18n-js/instance";
import { Prompt } from "../Prompt";

export const LoaderPrompt = () => (
  <Prompt>
    <Prompt.LoadingIcon />
    <Prompt.Text hasGradient>
      {t("tiptap.extensions.ai_copilot.input.generating")}
    </Prompt.Text>
  </Prompt>
);
