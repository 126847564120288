import { Row } from "@circle-react-uikit/SkeletonLoader/components";
import { HighlightViewSeparator } from "./HighlightViewSeparator";

export interface HighlightViewProps {
  index: number;
  highlight: string;
}

export const HighlightView = ({ index, highlight }: HighlightViewProps) => (
  <>
    {index > 0 && <HighlightViewSeparator />}
    <Row key={highlight} sm short />
  </>
);
