import { NodeViewWrapper } from "@tiptap/react";
import { t } from "@/i18n-js/instance";
import {
  coursePaths,
  posts,
  viewSpacePath,
} from "@circle-react/helpers/urlHelpers";
import { useEnterOrSpaceKeyDownHandler } from "@circle-react/hooks/useEnterOrSpaceKeyDownHandler";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { useBlockEditorContext } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { useTipTapEditorContext } from "@circle-react-uikit/TipTap/index";

export const EntityDisplay = ({ node }: any) => {
  const tiptapBlockEditorContext = useBlockEditorContext();
  const tiptapEditorContext = useTipTapEditorContext();

  const { sgidToObjectMap = {}, localSgidToObjectMap = {} } =
    tiptapEditorContext || tiptapBlockEditorContext;

  const entity =
    localSgidToObjectMap[node.attrs.sgid] || sgidToObjectMap[node.attrs.sgid];

  const redirectToEntity = () => {
    let path;
    switch (entity.type) {
      case "Space":
      case "space":
        path = viewSpacePath({ spaceSlug: entity.slug });
        break;
      case "Posts::Basic":
      case "Posts::Event":
      case "post":
        path = posts.show({ spaceSlug: entity.space_slug, slug: entity.slug });
        break;
      case "Courses::Lesson":
      case "lesson":
        path = coursePaths.showLesson({
          spaceSlug: entity.space_slug,
          sectionId: entity.section_id,
          lessonId: entity.id,
        });
        break;
      default:
        break;
    }

    if (!path) {
      return;
    }

    window.open(path, "_blank", "noreferrer");
  };

  const onKeyDownHandler = useEnterOrSpaceKeyDownHandler(redirectToEntity);

  if (!entity) {
    return (
      <NodeViewWrapper as="span">
        <TippyV2
          interactive={false}
          content={t("unavailable_entity_message")}
          placement="top"
        >
          <span className="text-circle mx-0.5 cursor-pointer rounded-sm bg-indigo-100 p-0.5 font-medium">
            {t("unavailable_entity")}
          </span>
        </TippyV2>
      </NodeViewWrapper>
    );
  }

  const isSpaceEntity = entity.type === "space" || entity.type === "Space";
  return (
    <NodeViewWrapper as="span">
      <TippyV2
        interactive={false}
        content={`${isSpaceEntity ? "#" : ""}${String(entity.name)}`}
        placement="top"
        disabled={!entity.name}
      >
        {/* eslint-disable-next-line jsx-a11y/prefer-tag-over-role -- This component can be a child of a <s> tag */}
        <span
          role="button"
          onClick={redirectToEntity}
          onKeyDown={onKeyDownHandler}
          contentEditable={false}
          className="text-circle mx-0.5 cursor-pointer rounded-sm bg-indigo-100 p-0.5 text-left font-medium"
          tabIndex={0}
        >
          {isSpaceEntity && "#"}
          {entity.name || t("tiptap.entity_untitled")}
        </span>
      </TippyV2>
    </NodeViewWrapper>
  );
};
