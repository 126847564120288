import PropTypes from "prop-types";
import classNames from "classnames";
import first from "lodash/first";
import Flatpickr from "react-flatpickr";
import { Controller, useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";

export const DateInput = ({
  name,
  rules = {},
  disabled = false,
  fullWidth = false,
  minDate = "",
  maxDate = "",
  minuteIncrement = 15,
  required = false,
  withIcon = false,
  isStatic = false,
  dataTestId = "",
  ...passThrough
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <Component
          onChange={onChange}
          value={value}
          fullWidth={fullWidth}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          withIcon={withIcon}
          required={required}
          minuteIncrement={minuteIncrement}
          isStatic={isStatic}
          dateInputOptions={passThrough}
          dataTestId={dataTestId}
        />
      )}
    />
  );
};

const Component = ({
  onChange,
  value,
  fullWidth = false,
  disabled,
  minDate,
  maxDate,
  required,
  withIcon,
  minuteIncrement,
  isStatic,
  dateInputOptions,
  dataTestId,
}) => {
  const onChangeDate = dates => {
    if (first(dates)) {
      onChange(dates[0].toISOString());
    }
  };

  const options = {
    enableTime: true,
    dateFormat: "F d, Y G:i K",
    minDate: minDate,
    maxDate: maxDate,
    disableMobile: "true",
    minuteIncrement: minuteIncrement,
    static: isStatic,
    ...dateInputOptions,
  };

  return (
    <div className="form-input" data-testid={dataTestId}>
      <div
        className={classNames("flex", {
          "flatpickr--static": isStatic,
        })}
      >
        <Flatpickr
          className={classNames(
            "flatpickr bg-primary border-primary text-dark h-[46px] px-4 text-left text-base font-normal",
            {
              "w-full": fullWidth,
              "!cursor-not-allowed opacity-40": disabled,
              "!rounded-r-none !border-r-0": withIcon,
            },
          )}
          data-enable-time
          options={options}
          value={value}
          onValueUpdate={onChangeDate}
          disabled={disabled}
          placeholder={t("select_a_date")}
          required={required}
        />
        {Boolean(withIcon) && (
          <div
            className={classNames(
              "bg-primary border-primary relative -ml-px inline-flex items-center space-x-2 rounded-r-lg border border-l-0 px-4 py-2",
              {
                "cursor-not-allowed opacity-40": disabled,
              },
            )}
          >
            <Icon
              type="16-calendar"
              useWithFillCurrentColor
              size={16}
              className="text-light"
            />
          </div>
        )}
      </div>
    </div>
  );
};

Component.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  withIcon: PropTypes.bool,
  dateInputOptions: PropTypes.object,
  minuteIncrement: PropTypes.number,
  isStatic: PropTypes.bool,
  dataTestId: PropTypes.string,
};

DateInput.propTypes = {
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minuteIncrement: PropTypes.number,
  required: PropTypes.bool,
  withIcon: PropTypes.bool,
  rules: PropTypes.object,
  isStatic: PropTypes.bool,
  dataTestId: PropTypes.string,
};
