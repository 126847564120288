import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { Prompt } from "../../Prompt";
import { useAiBlockContext } from "../../hooks/useAiBlockContext";
import { useKeyboardNavigation } from "../../hooks/useKeyboardNavigation";

export const ResponseOptions = ({ promptResponse }) => {
  const { node, editor, cleanupNode } = useAiBlockContext();
  const { range } = node.attrs;
  const endPos = editor.state.selection.$to.after();

  const OPTIONS = [
    {
      label: t("tiptap.replace_selection"),
      value: "replace",
      onClick: () => {
        editor.commands.setTextSelection(range);
        editor.commands.insertContentAt(range, promptResponse);
        cleanupNode();
      },
    },
    {
      label: t("tiptap.insert_bellow"),
      value: "insert_below",
      onClick: () => {
        editor.commands.insertContentAt(endPos, promptResponse);
        cleanupNode();
      },
    },
  ];

  const onClick = index => {
    isFunction(OPTIONS[index].onClick) && OPTIONS[index].onClick();
  };

  const { activeIndex, setActiveIndex } = useKeyboardNavigation({
    optionsCount: OPTIONS.length,
    onEnter: index => {
      onClick(index);
    },
  });

  return (
    <Prompt.EditOptions
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      onClick={({ index }) => onClick(index)}
      options={OPTIONS}
      shouldShowEnterIcon
    />
  );
};
