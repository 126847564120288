import type { ReactNode } from "react";
import { useRef, useState } from "react";
import { Popover } from "@headlessui/react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";
import { Popup } from "./Popup";

const HEADLESS_UI_POPOVER_ROOT = "headless-ui-popover-root";

export interface TranscriptPopupPreviewProps {
  mediaTranscriptId: number;
  onSeek: (time: number) => void;
  onFullscreenSeek?: (time: number) => void;
  onFullscreenToggle?: () => void;
  renderFullscreenPlayer?: () => ReactNode;
  currentTime: number;
  transcriptPreview: string;
}

export const TranscriptPopupPreview = ({
  mediaTranscriptId,
  onSeek,
  onFullscreenSeek,
  onFullscreenToggle,
  renderFullscreenPlayer,
  currentTime,
  transcriptPreview,
}: TranscriptPopupPreviewProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    onFullscreenToggle?.();
    setIsFullscreen(!isFullscreen);
  };

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  const togglePopup = () => buttonRef.current?.click();

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <blockquote>
            <div className="text-left">
              <Typography.BodyXs as="div">
                {transcriptPreview}
              </Typography.BodyXs>
            </div>
            <Popover.Button as="div" className="flex" ref={setReferenceElement}>
              <button
                type="button"
                className="text-link text-xs hover:underline"
                ref={buttonRef}
              >
                {open
                  ? t("media_player.hide_transcript")
                  : t("media_player.show_transcript")}
              </button>
            </Popover.Button>
          </blockquote>
          {createPortal(
            <Popover.Panel
              as="div"
              className="z-[10000]"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <Popup
                toggleFullscreen={toggleFullscreen}
                togglePopup={togglePopup}
                onSeek={onSeek}
                mediaTranscriptId={mediaTranscriptId}
                currentTime={currentTime}
                onFullscreenSeek={onFullscreenSeek}
                renderFullscreenPlayer={renderFullscreenPlayer}
                isFullscreen={isFullscreen}
              />
            </Popover.Panel>,
            document.getElementById(HEADLESS_UI_POPOVER_ROOT) || document.body,
          )}
        </>
      )}
    </Popover>
  );
};
