import type { Editor, Range } from "@tiptap/react";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

export const useCreateEmbed = ({
  editor,
  range,
  addToLocalSgidToObjectMap,
}: {
  editor: Editor;
  range: Range;
  addToLocalSgidToObjectMap: (params: { sgid: string; object: any }) => void;
}) => {
  const toast = useToast();
  const drawer = useModal();
  return useMutation(
    url =>
      reactQueryPost(
        internalApi.richTextFields.createOembed({ params: { url } }),
      ),
    {
      onSuccess: data => {
        if (!data?.oembed) {
          return toast.error(t("courses.edit.lesson.embed_failed"));
        }
        editor
          .chain()
          .focus()
          .insertContentAt(range, {
            type: "embed",
            attrs: {
              sgid: data.sgid,
            },
          })
          .run();
        addToLocalSgidToObjectMap({ sgid: data.sgid, object: data.oembed });
        drawer.remove();
      },
      onError: (_, url) => {
        if (typeof url !== "string") {
          return;
        }
        editor
          .chain()
          .focus()
          .insertContentAt(range, {
            type: "paragraph",
            content: [
              {
                type: "text",
                text: url,
                marks: [
                  {
                    type: "link",
                    attrs: { href: url, target: "_blank" },
                  },
                ],
              },
            ],
          })
          .run();
        drawer.remove();
      },
    },
  );
};
