import { t } from "@/i18n-js/instance";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { CoverImageOverlay } from "@circle-react-uikit/CoverImageInput/CoverImageOverlay";
import type { RendererProps } from "@circle-react-uikit/ImageInput/ImageSelector";

export interface CoverImageInputPanelProps extends RendererProps {}

export const CoverImageInputPanel = ({
  changeImageText = t("edit_cover_image"),
  handleChangeCoverClick,
  handleDelete,
  imageUrl,
  processing,
  value,
}: CoverImageInputPanelProps) => {
  const isProcessing = Boolean(processing);
  const hasValue = Boolean(value);

  return (
    <div className="cover-image-input__preview">
      {isProcessing && (
        <div className="quickpost-progress">
          <LoaderRow />
        </div>
      )}
      {hasValue && !isProcessing && (
        <>
          <img loading="lazy" src={imageUrl} alt="" />
          <CoverImageOverlay
            onDelete={handleDelete}
            onChange={handleChangeCoverClick}
            removeButtonText={t("remove_cover_image")}
            changeButtonText={changeImageText}
          />
        </>
      )}
    </div>
  );
};
