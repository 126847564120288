import classNames from "classnames";
import { Row } from "@circle-react-shared/uikit/SkeletonLoader/components/Row";
import { AccessRegistryCardView } from "../../../AccessRegistryCardView";
import { AccessGroupCardControlView } from "./AccessGroupCardControlView";
import { ContentTableView } from "./ContentTableView";

export interface CommunityMemberAccessControlViewProps {
  className?: string;
}

export const CommunityMemberAccessControlView = ({
  className,
}: CommunityMemberAccessControlViewProps) => (
  <div
    className={classNames(
      "skeleton-loader skeleton-loader--admin-member-edit-modal-community-member-access-control flex flex-col gap-y-12 py-12",
      className,
    )}
  >
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col">
        <Row lg long />
        <Row sm extraLong />
      </div>
      <Row xl full />
    </div>
    <div className="flex flex-col gap-y-6">
      <Row lg long />
      <AccessGroupCardControlView />
      <AccessRegistryCardView highlights={["1"]}>
        <ContentTableView />
      </AccessRegistryCardView>
    </div>
  </div>
);
