import { Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import "./styles.scss";

const invisibleCharacter = "&#8203;";

/** @deprecated Please use the new Modal components at "@circle-react-uikit/ModalV2" */
export const DefaultModal = ({
  id,
  children,
  show,
  onClose,
  title,
  className = "px-6 py-6",
  hideCloseButton,
  overflowVisible = false,
  containerClassName = "default",
}) => (
  <Transition.Root show={show} as={Fragment}>
    <Dialog
      as="div"
      static
      className={`react-modal react-modal--default ${containerClassName}`}
      open={show}
      onClose={onClose}
    >
      <div className="react-modal__wrapper">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-900/50" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          style={{ content: invisibleCharacter }}
        />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            id={id}
            className={classNames(
              "react-modal__container",
              {
                "overflow-hidden": !overflowVisible,
              },
              className,
            )}
          >
            <div className="react-modal__close-button-container">
              {!hideCloseButton && (
                <button
                  type="button"
                  className="react-modal__close-button"
                  onClick={onClose}
                >
                  <span className="sr-only">{t("close")}</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              )}
            </div>
            <div>
              <Dialog.Title
                as="h3"
                className="text-darkest text-lg font-medium leading-6"
              >
                {title}
              </Dialog.Title>
              <div className="h-full">{children}</div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

DefaultModal.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  overflowVisible: PropTypes.bool,
  containerClassName: PropTypes.string,
};
