import * as yup from "yup";
import { t } from "@/i18n-js/instance";

// Support all Youtube, Vimeo and Wistia video URLs
const SUPPORTED_DOMAINS = [
  "youtube.com",
  "youtu.be",
  "vimeo.com",
  "wistia.com",
  "wi.st",
  "typeform.com",
  "videoask.com",
  "loom.com",
  "spotify.com",
  "spoti.fi",
  "simplecast.com",
  "brightcove.com",
  "brightcove.net",
  "bcove.video",
  "bcove.me",
  "panopto.com",
  "panopto.eu",
];

const validateSupportedEmbedURL = url => {
  try {
    const host = new URL(url).host;
    return SUPPORTED_DOMAINS.some(domain => host.includes(domain));
  } catch (err) {
    return false;
  }
};

export const getEmbedValidationSchema = ({
  allowUnsupportedURLs = false,
} = {}) => {
  const invalidURLMessage = t("courses.edit.lesson.embed_invalid_url");
  if (allowUnsupportedURLs) {
    return yup.object().shape({
      url: yup.string().required(invalidURLMessage).url(invalidURLMessage),
    });
  }
  return yup.object().shape({
    url: yup
      .string()
      .required(invalidURLMessage)
      .url(invalidURLMessage)
      .test("supported-url", invalidURLMessage, validateSupportedEmbedURL),
  });
};
