import type { Editor } from "@tiptap/react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { AlignmentButton } from "../AlignmentButton";
import { useImageLinkModal } from "./ImageLinkModal";

interface EmailImageToolbarProps {
  editor: Editor;
  node: any;
  updateAttributes: (attrs: any) => void;
  deleteNode: () => void;
}

export const EmailImageToolbar = ({
  editor,
  node,
  updateAttributes,
  deleteNode,
}: EmailImageToolbarProps) => {
  const { attrs } = node;

  /* Older emails would have images in previous format. These should not trigger an active status on this toolbar  */
  const isLeftAligned = attrs.use_intrinsic_size && attrs.alignment === "left";
  const isRightAligned = attrs.alignment === "right";
  const isFullWidth = attrs.width_ratio === 1;
  const isCentered = attrs.use_intrinsic_size && attrs.alignment === "center";

  const imageLinkModal = useImageLinkModal();

  return (
    <div
      className={classNames(
        "invisible absolute left-0 top-0 inline-flex w-full -translate-y-full items-center pb-1 hover:visible group-hover:visible group-active:visible",
        {
          "justify-start": isLeftAligned,
          "justify-center": isCentered,
          "justify-end": isRightAligned || isFullWidth,
        },
        {
          /* Handle cases where the image does not have an active alignment on this toolbar */
          "justify-end":
            !isLeftAligned && !isRightAligned && !isFullWidth && !isCentered,
        },
      )}
    >
      <div className="bg-text-editor-modifier flex rounded-md p-1 text-sm">
        <div className="flex gap-x-1">
          <AlignmentButton
            onClick={() => {
              updateAttributes({
                alignment: "left",
                width_ratio: 0,
                use_intrinsic_size: true,
              });
            }}
            isActive={isLeftAligned}
            iconType="16-image-left"
          />
          <AlignmentButton
            onClick={() => {
              updateAttributes({
                alignment: "center",
                width_ratio: 0,
                use_intrinsic_size: true,
              });
            }}
            isActive={isCentered}
            iconType="16-image-centre"
          />
          <AlignmentButton
            onClick={() => {
              updateAttributes({
                alignment: null,
                width_ratio: 1,
                use_intrinsic_size: true,
              });
            }}
            isActive={isFullWidth}
            iconType="16-image-full-width"
          />
          <AlignmentButton
            onClick={() => {
              updateAttributes({
                alignment: "right",
                width_ratio: 0,
                use_intrinsic_size: true,
              });
            }}
            isActive={isRightAligned}
            iconType="16-image-right"
          />
        </div>
        <span
          className="text-default m-1 inline-block w-px self-stretch bg-current"
          aria-hidden
        />
        <div className="flex gap-x-1">
          <AlignmentButton
            onClick={() => imageLinkModal.show({ editor })}
            isActive={Boolean(attrs.href)}
            iconType="16-interface-link"
          />
          <AlignmentButton
            onClick={deleteNode}
            isActive={false}
            iconType="16-trash"
          />
        </div>
      </div>
    </div>
  );
};
