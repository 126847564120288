import { useRef } from "react";
import type { Editor } from "@tiptap/core";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { useThemeContext } from "@circle-react/providers";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Modal } from "@circle-react-uikit/ModalV2";
import { ButtonForm } from "./ButtonForm";

interface SettingsModalProps {
  editor: Editor;
  range: { from: number; to: number };
}

export const CTASettingsModalComponent = ({
  editor,
  range,
}: SettingsModalProps) => {
  const drawer = useModal();
  const theme = useThemeContext();
  const inputRef = useRef<HTMLInputElement>(null);

  if (!editor) return null;

  const defaultColor = theme.brandColor[theme.currentAppearance] ?? "#000000";

  const getDefaultValues = () => {
    const {
      label = "",
      url = "",
      color = defaultColor,
    } = editor.isActive("cta") ? editor.getAttributes("cta") : {};
    return { label, url, color };
  };

  const defaultValues = getDefaultValues();
  const isEdit = !!defaultValues.label;

  return (
    <Modal
      isOpen={drawer.visible}
      onClose={drawer.remove}
      initialFocus={inputRef}
    >
      <Modal.Overlay />

      <Modal.Content>
        <Modal.Header>
          <Modal.Title size="sm">
            <Typography.LabelLg weight="semibold">
              {isEdit
                ? t("email_editor.cta.edit_title")
                : t("email_editor.cta.title")}
            </Typography.LabelLg>
          </Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={drawer.remove} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          <ButtonForm
            inputRef={inputRef}
            range={range}
            editor={editor}
            onClose={drawer.remove}
            defaultValues={defaultValues}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const CTASettingsModal = ModalManager.create(
  ({ editor, range }: SettingsModalProps) => (
    <CTASettingsModalComponent editor={editor} range={range} />
  ),
);

export const useCTASettingsModal = () => useModal(CTASettingsModal);
