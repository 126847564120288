import PropTypes from "prop-types";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { WebVTTParser } from "webvtt-parser";
import { t } from "@/i18n-js/instance";
import { planSettingsPath } from "@circle-react/helpers/urlHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { RadioCard } from "@circle-react-uikit/RadioCard";
import { FileUpload } from "./FileUpload";

const FileTypeSelectComponent = ({
  onChange,
  value,
  supportsAi,
  aiFileDownloadUrl,
}) => {
  const validateVttFile = vttFileValue => {
    if (!vttFileValue) {
      return true;
    }
    const vttText = atob(vttFileValue.replace(/data:\S+,/, ""));
    const parser = new WebVTTParser();
    const result = parser.parse(vttText);
    if (!result?.errors?.length) {
      return true;
    }
    return t("media_player.transcript_menu.file_type.user_file_error");
  };

  const onUpgradeClick = e => {
    e.preventDefault();
    window.open(planSettingsPath(), "_blank");
  };

  const onAiFileDownloadClick = e => {
    e.preventDefault();
    window.open(aiFileDownloadUrl, "_blank");
  };

  const isUserFile = value === "user";
  const isAiFile = value === "ai";
  const shouldDisplayUploadButton = isUserFile;
  const shouldDisplayUpgradeButton =
    isAiFile && !supportsAi && !aiFileDownloadUrl;
  const shouldDisplayAiFileDownloadButton = isAiFile && !!aiFileDownloadUrl;

  return (
    <RadioGroup
      value={value}
      name="file_type"
      onChange={onChange}
      className="w-full"
    >
      <div
        className={classNames("flex flex-col items-stretch gap-3", {
          "flex-col-reverse": !supportsAi,
        })}
      >
        <RadioCard value="ai" className="py-4">
          <RadioGroup.Label
            as="span"
            className="text-darkest block text-sm font-medium"
          >
            {t("media_player.transcript_menu.file_type.ai")}
          </RadioGroup.Label>
          <RadioGroup.Description
            as="span"
            className="text-default mt-1 flex flex-col justify-center gap-1 text-sm"
          >
            {t("media_player.transcript_menu.file_type.ai_description")}
            {shouldDisplayUpgradeButton && (
              <Button variant="circle" onClick={onUpgradeClick}>
                {t("media_player.transcript_menu.file_type.upgrade_label")}
              </Button>
            )}
            {shouldDisplayAiFileDownloadButton && (
              <Button variant="secondary" onClick={onAiFileDownloadClick}>
                <Icon type="16-download" className="mr-1.5" size={16} />
                {t("media_player.transcript_menu.file_type.download_vtt_label")}
              </Button>
            )}
          </RadioGroup.Description>
        </RadioCard>
        <RadioCard value="user" className="py-4">
          <RadioGroup.Label
            as="span"
            className="text-darkest block text-sm font-medium"
          >
            {t("media_player.transcript_menu.file_type.user")}
          </RadioGroup.Label>
          <RadioGroup.Description
            as="div"
            className="text-default mt-1 flex flex-col justify-center gap-1 text-sm"
          >
            {t("media_player.transcript_menu.file_type.user_description")}
            {shouldDisplayUploadButton && (
              <FileUpload
                name="user_webvtt_file"
                rules={{ validate: validateVttFile }}
              />
            )}
          </RadioGroup.Description>
        </RadioCard>
      </div>
    </RadioGroup>
  );
};

FileTypeSelectComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  supportsAi: PropTypes.bool,
  aiFileDownloadUrl: PropTypes.string,
};

export const FileTypeSelect = ({ name, rules, ...passThroughProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <FileTypeSelectComponent
          {...passThroughProps}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};

FileTypeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
};
