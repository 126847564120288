import { useEffect } from "react";
import { useBlockEditorContext } from "..";
import type { Editor } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
import classNames from "classnames";
import InnerHTML from "dangerously-set-html-content";
import { truncate } from "lodash";
import { useQuery } from "react-query";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { getIframeSizeProps } from "./utils";

// Embeds without an iframe wrapper
const NATIVE_EMBEDS = [
  "Facebook",
  "Instagram",
  "Twitter", // Backwards compatibility
  "X (Formerly Twitter)",
];

const oembedLoaders: any = {
  Facebook: () =>
    window.FB?.init?.({
      xfbml: true,
      version: "v12.0",
    }),
  Instagram: () => window.instgrm?.Embeds?.process?.(),
  Twitter: () => window.twttr?.widgets?.load?.(),
};

export interface EmbedPreviewProps {
  node: any;
  editor: Editor;
}

export const EmbedPreview = ({ node, editor }: EmbedPreviewProps) => {
  const { isEditable } = editor;
  const { sgidToObjectMap, localSgidToObjectMap, addToLocalSgidToObjectMap } =
    useBlockEditorContext() || {};
  const sgid = node?.attrs?.sgid;
  const embed = sgidToObjectMap[sgid] || localSgidToObjectMap[sgid];

  const { isLoading } = useQuery(
    internalApi.richTextFields.fetchOembed({ sgid }),
    {
      enabled: !embed && isEditable,
      retry: false,
      retryOnMount: false,
      onSuccess: data => {
        addToLocalSgidToObjectMap?.({
          sgid,
          object: data,
        });
      },
    },
  );

  useEffect(() => {
    if (embed?.provider_name) {
      oembedLoaders[embed.provider_name]?.();
    }
  }, [embed?.provider_name]);

  if (isLoading) {
    return (
      <NodeViewWrapper as="div">
        <Icon type="loader" size={16} />
      </NodeViewWrapper>
    );
  }

  if (!embed) {
    return null;
  }

  const isLinkPreview = !embed.html && embed.url;

  if (isLinkPreview) {
    return (
      <NodeViewWrapper as="div">
        <a
          href={embed.url}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-secondary hover:bg-tertiary border-primary group relative inline-flex max-w-full items-center justify-between space-x-4 break-all rounded border px-3 py-2"
        >
          <div>
            <div>
              <Typography.LabelSm weight="semibold">
                {truncate(embed.title, {
                  length: 100,
                })}
              </Typography.LabelSm>
            </div>
            {embed.description && (
              <div className="mb-2">
                <Typography.LabelSm>
                  {truncate(embed.description, {
                    length: 100,
                  })}
                </Typography.LabelSm>
              </div>
            )}
            <div>
              <Typography.LabelSm color="text-light">
                {truncate(embed.url, {
                  length: 100,
                })}
              </Typography.LabelSm>
            </div>
          </div>
          {embed.thumbnail_url && (
            <div className="shrink-0">
              <img
                loading="lazy"
                src={embed.thumbnail_url}
                alt={embed.title}
                className="h-16 w-16 rounded object-contain"
              />
            </div>
          )}
        </a>
      </NodeViewWrapper>
    );
  }

  const isNativeEmbed = NATIVE_EMBEDS.includes(embed.provider_name);
  const iframeSizeProps = getIframeSizeProps({ embed });

  return (
    <NodeViewWrapper as="div">
      {isNativeEmbed || embed.embed_provider === "iframely" ? (
        <InnerHTML className="overflow-auto" html={embed.html} />
      ) : (
        <iframe
          loading="lazy"
          className={classNames("w-full max-w-full")}
          src={embed.circle_embed_url}
          title={embed.title}
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowFullScreen
          {...iframeSizeProps}
        />
      )}
    </NodeViewWrapper>
  );
};
