import { times } from "lodash";
import {
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

const marginClassName = "ml-6";

export interface CommunitySidebarV2Props {
  count?: number;
}

export const CommunitySidebarSkeleton = ({
  count = 5,
}: CommunitySidebarV2Props) => (
  <div className="relative grid h-[calc(100vh-65px)] grid-rows-[1fr]">
    <div className="h-full max-w-[18.5rem] overflow-hidden pb-0 pl-0">
      <div className="flex h-20 min-h-[5rem] items-center px-6">
        <Row long />
      </div>
      <div className="space-y-5">
        {times(count, index => (
          <div key={index} className="items-center px-6">
            <RowContainer xl>
              <Row half lg />
              <Row almostFull className={marginClassName} />
              <Row almostFull className={marginClassName} />
              <Row almostFull className="mb-2" />
            </RowContainer>
          </div>
        ))}
      </div>
    </div>
  </div>
);
