import { forwardRef, useEffect, useState } from "react";
import { defer, isArray } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInternalEditorState } from "@circle-react-shared/uikit/TipTap/internalEditorState";
import { useBlockEditorContext } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { Loader } from "@circle-react-uikit/Loader";
import { useTipTapEditorContext } from "@circle-react-uikit/TipTap/index";
import { useKeyboardHandler } from "../hooks/useKeyboardHandler";
import { MentionItem } from "./MentionItem";
import { getMentionScope } from "./helper";
import { useMentions } from "./hooks/useMentions";

interface MentionListProps {
  query: string;
  command: (item: any) => void;
}
export const MentionList = forwardRef(
  ({ query, command }: MentionListProps, ref) => {
    const tiptapEditorContext = useTipTapEditorContext();
    const tiptapBlockEditorContext = useBlockEditorContext();
    const {
      addToLocalSgidToObjectMap,
      spaceId,
      chatProps,
      type: editorType,
      isUpdate,
    } = tiptapEditorContext || tiptapBlockEditorContext;

    const { isThread, isDirectMessaging, disableGroupMentions } =
      chatProps || {};
    const { setPopupsVisible } = useInternalEditorState();

    const { items, fetchNextPage, hasNextPage, isLoading } = useMentions({
      query,
      spaceId: spaceId,
      scope: getMentionScope({
        spaceId,
        isDirectMessaging,
        isThread,
        editorType,
        isUpdate,
        disableGroupMentions,
      }),
    });

    const hasItems = isArray(items) && items.length > 0;

    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index: number) => {
      const item = items[index];
      if (item) {
        addToLocalSgidToObjectMap({ sgid: item.sgid, object: item });
        command(item);
      }
    };

    useEffect(() => setSelectedIndex(0), [items]);

    useKeyboardHandler({
      selectedIndex,
      setSelectedIndex,
      ref,
      items,
      selectItem,
    });

    useEffect(() => {
      if (!query || isLoading || !hasItems) {
        setPopupsVisible(false);
      } else {
        setPopupsVisible(true);
      }
    }, [hasItems, query, setPopupsVisible, isLoading]);

    if (!hasItems) {
      return (
        <div className="h-[50vh] min-w-[250px] max-w-[320px] bg-transparent" />
      );
    }
    return (
      <div
        className="bg-primary border-primary relative flex max-h-[50vh] min-w-[250px] max-w-[320px] flex-col space-y-0.5 overflow-y-auto rounded border py-1.5 text-sm shadow-md"
        id="mentionList"
      >
        <InfiniteScroll
          scrollThreshold={0.8}
          next={fetchNextPage}
          hasMore={Boolean(hasNextPage)}
          dataLength={items.length}
          loader={<Loader center />}
          scrollableTarget="mentionList"
        >
          {items.map((member, index) => (
            <MentionItem
              key={member.id}
              member={member}
              handleClick={() => {
                selectItem(index);
                defer(() => setPopupsVisible(false));
              }}
              isSelected={index === selectedIndex}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  },
);

MentionList.displayName = "MentionList";
