import type { ChangeEvent } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";

export type Variant = "default" | "large";

export const VARIANTS = {
  DEFAULT: "default",
  LARGE: "large",
} as const;

export interface ToggleSwitchProps {
  field: string;
  value?: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  variant?: Variant;
  className?: string;
  labelClassName?: string;
  wrapperClassName?: string;
  label?: string;
}

export const ToggleSwitch = ({
  field,
  value = false,
  disabled = false,
  onChange,
  variant = "default",
  className,
  labelClassName,
  wrapperClassName,
  label = t("toggle_switch"),
}: ToggleSwitchProps) => (
  <div
    className={classNames("toggle-switch", wrapperClassName, {
      "toggle-switch--small": variant === VARIANTS.DEFAULT,
      "toggle-switch--large": variant === VARIANTS.LARGE,
    })}
    id={`field-${field}`}
  >
    <input
      type="checkbox"
      name={field}
      id={`checkbox-${field}`}
      checked={value}
      onChange={onChange}
      disabled={disabled}
      className={classNames("peer", className)}
    />
    <label
      htmlFor={`checkbox-${field}`}
      className={classNames(
        "bg-toggle-unchecked before:bg-toggle-circle-unchecked peer-checked:bg-toggle-checked before:peer-checked:bg-toggle-circle-checked relative",
        labelClassName,
      )}
    >
      <span className="sr-only">{label}</span>
    </label>
  </div>
);
