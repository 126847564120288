import type { ReactNode } from "react";
import classNames from "classnames";
import noop from "lodash/noop";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Switch } from "@circle-react-uikit/Switch";
import { ToggleSwitch } from "./ToggleSwitch";

export interface ToggleSwitchWithLabelProps {
  label?: string;
  field: string;
  value?: boolean;
  onChange?: (value: any) => void;
  tooltipText?: string | ReactNode;
  className?: string;
  labelClassName?: string;
  toggleSwitchClassName?: string;
  toggleSwitchLabelClassName?: string;
  variant?: "default" | "large" | "small";
  disabled?: boolean;
}

export const ToggleSwitchWithLabel = ({
  label = t("toggle_switch"),
  field,
  value = false,
  onChange = noop,
  tooltipText,
  className,
  labelClassName,
  toggleSwitchClassName,
  toggleSwitchLabelClassName,
  variant = "default",
  disabled = false,
}: ToggleSwitchWithLabelProps) => (
  <div
    className={classNames(
      "flex flex-row items-center justify-between",
      className,
    )}
  >
    {!tooltipText ? (
      <span className={classNames("text-default", labelClassName)}>
        {label}
      </span>
    ) : (
      <span className="text-default flex items-center">
        {label}
        <TippyV2 content={tooltipText} className="inline-flex">
          <Icon type="question-mark" className="ml-2 !w-3" />
        </TippyV2>
      </span>
    )}
    <div id={`field-${field}`} className="flex items-center">
      {variant === "small" ? (
        <>
          <input
            type="checkbox"
            name={field}
            id={`checkbox-${field}`}
            checked={value}
            onChange={onChange}
            className="peer hidden"
          />
          <label className="m-0" htmlFor={`checkbox-${field}`}>
            <Switch as="span" checked={value} onChange={onChange} />
            <span className="sr-only">{label}</span>
          </label>
        </>
      ) : (
        <ToggleSwitch
          field={field}
          value={value}
          onChange={onChange}
          variant={variant}
          disabled={disabled}
          wrapperClassName={toggleSwitchClassName}
          labelClassName={toggleSwitchLabelClassName}
        />
      )}
    </div>
  </div>
);
