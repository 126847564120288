import { Row } from "@circle-react-uikit/SkeletonLoader/components";
import { HighlightView } from "./HighlightView";

export interface AccessRegistryCardViewProps {
  badge?: boolean;
  highlights?: string[];
  children?: React.ReactNode;
}
export const AccessRegistryCardView = ({
  badge,
  highlights,
  children,
}: AccessRegistryCardViewProps) => (
  <div className="skeleton-loader skeleton-loader--access-registry-card bg-primary border-primary flex flex-col gap-y-2 rounded-lg border p-6">
    <div className="flex flex-row items-center gap-x-2">
      <Row lg extraLong />
      {badge && <Row lg badge />}
    </div>
    <div className="flex flex-row items-center gap-x-2">
      {highlights &&
        highlights.map((highlight, index) => (
          <HighlightView key={highlight} index={index} highlight={highlight} />
        ))}
    </div>
    {children && (
      <div className="border-primary mt-4 flex flex-col border-t pt-4">
        {children}
      </div>
    )}
  </div>
);
