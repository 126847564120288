import { Table } from "@circle-react-shared/uikit/TableV2";
import { Row } from "@circle-react-uikit/SkeletonLoader/components";

export interface ContentTableViewRowsProps {
  header: string;
  items: string[];
}

export const ContentTableViewRows = ({
  header,
  items,
}: ContentTableViewRowsProps) => (
  <>
    <Table.Tr key={header}>
      <Table.Td
        hideBorders
        colSpan={3}
        className="truncate px-0 py-2 align-middle"
      >
        <Row lg half />
      </Table.Td>
    </Table.Tr>
    {items.map(item => (
      <Table.Tr key={item}>
        <Table.Td
          hideBorders
          className="w-[300px] truncate px-0 py-2 align-middle"
        >
          <div className="flex flex-row items-center gap-x-2">
            <Row md short />
            <Row md extraLong />
          </div>
        </Table.Td>
        <Table.Td
          hideBorders
          className="w-[150px] truncate px-0 py-2 align-middle"
        >
          <Row md long />
        </Table.Td>
        <Table.Td
          hideBorders
          className="flex w-[200px] truncate px-0 py-2 align-middle"
        >
          <Row md long />
        </Table.Td>
      </Table.Tr>
    ))}
  </>
);
