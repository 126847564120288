import { forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import type { FormInputProps } from "@circle-react-uikit/Form/FormInput";
import { FormInput } from "@circle-react-uikit/Form/FormInput";
import { prependHttps } from "./prependHttps";

export const LinkInput = forwardRef<HTMLInputElement, FormInputProps>(
  ({ name, onBlur, ...rest }, ref) => {
    const { setValue } = useFormContext();

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const newValue = prependHttps(e.target.value);
      setValue(name, newValue, { shouldValidate: true });
      onBlur?.(e);
    };

    return (
      <FormInput
        {...rest}
        ref={ref}
        type="url"
        name={name}
        onBlur={handleOnBlur}
      />
    );
  },
);

LinkInput.displayName = "LinkInput";
