const isImage = content_type =>
  ["image/gif", "image/jpeg", "image/png"].includes(content_type);
const isVideo = content_type => content_type.startsWith("video/");
const isNativelySupportedVideo = content_type =>
  ["video/mp4", "video/mpeg", "video/ogg"].includes(content_type);
const isAudio = content_type =>
  ["audio/mpeg", "audio/ogg", "audio/mp4", "audio/wav", "audio/x-wav"].includes(
    content_type,
  );
const isPDF = content_type => content_type.startsWith("application/pdf");

export const contentTypeParser = {
  isImage,
  isVideo,
  isNativelySupportedVideo,
  isAudio,
  isPDF,
};

export const supportsGalleryPreview = content_type => isImage(content_type);
