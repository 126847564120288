import { t } from "@/i18n-js/instance";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { Button } from "@circle-react-shared/uikit/Button";

interface FooterProps {
  isEdit: boolean;
  isLoading: boolean;
}

export const Footer = ({ isEdit, isLoading }: FooterProps) => {
  const modal = useModal();
  return (
    <footer
      className="border-primary flex justify-end gap-x-4 border-t px-5 py-4"
      data-testid="poll-modal-footer"
    >
      <Button onClick={modal.remove} variant="secondary" type="button">
        {t("tiptap.extensions.poll.cancel")}
      </Button>
      <Button variant="circle" type="submit" disabled={isLoading}>
        {isEdit
          ? t("tiptap.extensions.poll.save")
          : t("tiptap.extensions.poll.insert")}
      </Button>
    </footer>
  );
};
