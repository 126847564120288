import { useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useAiBlockContext } from "./hooks/useAiBlockContext";

export const CleanupWrapper = ({ children }) => {
  const wrapperRef = useRef(null);
  const { isLoadingResponse: isCloseDisabled, cleanupNode } =
    useAiBlockContext();

  useHotkeys("esc", cleanupNode, {
    enableOnFormTags: ["INPUT"],
    enableOnContentEditable: true,
    enabled: !isCloseDisabled,
  });

  useEffect(() => {
    const handleClick = event => {
      if (isCloseDisabled) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        cleanupNode();
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [isCloseDisabled, cleanupNode]);

  return <div ref={wrapperRef}>{children}</div>;
};
