import * as yup from "yup";
import { t } from "@/i18n-js/instance";

export const VALIDATION_SCHEMA = yup.object().shape({
  url: yup.lazy(value => {
    if (value.startsWith("mailto:")) {
      return yup
        .string()
        .trim()
        .matches(/([mailto:\w.\-pL]+@\w+.[\w.\-pL]+)/, t("link_url_invalid"))
        .required(t("link_url_required"));
    }
    return yup
      .string()
      .url(t("link_url_invalid"))
      .required(t("link_url_required"));
  }),
});
