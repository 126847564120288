import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { uniqueId } from "lodash";
import I18n from "@/i18n-js/instance";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Tabs } from "@circle-react-uikit/Tabs";
import { Icon } from "../../Icon";
import { EmbedUpload } from "../../ImageEditorModalWithCrop/EmbedUpload";
import { FileUpload } from "../../ImageEditorModalWithCrop/FileUpload";
import { UnsplashTab } from "../../Unsplash/UnsplashTab";
import { UnsplashTabContent } from "../../Unsplash/UnsplashTabContent";
import { unsplashCreateApi } from "../../Unsplash/unsplashCreateApi";
import { Uploader } from "../../Uploader";
import { Typography } from "../Typography";

export const ImagePickerModalV2 = ({ isOpen, onClose, onComplete }) => {
  const [unsplashResults, setUnsplashResults] = useState([]);
  const [progress, setProgress] = useState(0);
  const [imageError, setImageError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [embedUrl, setEmbedUrl] = useState("");

  const onUploadComplete = (url, signed_id, content_type) => {
    setIsProcessing(false);
    setProgress(0);
    onComplete({ url, signed_id, content_type });
  };

  const uploadAndReturnUrl = async file => {
    const uploader = new Uploader(file, onUploadComplete, "", "", setProgress);
    uploader.start();
  };

  const isValidImage = type => {
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
    return acceptedImageTypes.includes(type);
  };

  const fetchFromRemoteAndReturnUrl = async ({ url, id = uniqueId() }) => {
    try {
      setIsProcessing(true);
      const res = await fetch(url);
      const blob = await res.blob();

      if (isValidImage(res.headers.get("content-type"))) {
        const file = new File([blob], id, {
          type: "image/jpeg",
        });
        uploadAndReturnUrl(file);
      } else {
        setImageError(I18n.t("image_upload.invalid_file_type_error"));
        setIsProcessing(false);
      }
    } catch (e) {
      setImageError(I18n.t("image_upload.embed_network_error"));
      setIsProcessing(false);
    }
  };

  const addUnsplashImage = item =>
    fetchFromRemoteAndReturnUrl({
      url: item.urls.regular,
      id: item.id,
    });

  const addEmbed = () => fetchFromRemoteAndReturnUrl({ url: embedUrl });

  const searchUnsplash = async query => {
    if (!query.trim().length) return;
    const search = await unsplashCreateApi.search.getPhotos({
      query: query.trim(),
      page: 1,
      perPage: 30,
      orientation: "landscape",
    });

    const results = search.response.results;
    if (results.length >= 1) {
      setUnsplashResults(results);
    }
  };

  useEffect(() => {
    searchUnsplash("wallpapers");
  }, []);

  const insertFiles = files => {
    const file = files[0];
    if (file && isValidImage(file.type)) {
      setIsProcessing(true);
      setImageError("");
      uploadAndReturnUrl(file);
    } else {
      setImageError(I18n.t("image_upload.invalid_file_type_error"));
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="editor-modal trix-modal__upload"
    >
      <Modal.Overlay />
      <Modal.Content
        className="relative transition-all duration-200"
        size={isProcessing ? "sm" : "lg"}
      >
        {!isProcessing && (
          <div className="absolute right-4 top-4">
            <Modal.CloseButton onClick={onClose} />
          </div>
        )}
        <Modal.Body>
          {isProcessing ? (
            <div
              className={classNames(
                "bg-primary pointer-events-none flex w-full flex-col items-center justify-center py-6",
              )}
            >
              <Icon type="loader" />
              <div className="mt-2 flex flex-col items-center">
                {progress ? (
                  <Typography.LabelSm weight="medium">
                    {I18n.t("image_upload.progress", { progress })}
                  </Typography.LabelSm>
                ) : (
                  <Typography.LabelSm weight="medium">
                    {I18n.t("image_upload.preparing")}
                  </Typography.LabelSm>
                )}
              </div>
            </div>
          ) : (
            <Tabs defaultValue={I18n.t("upload")} controlled={false}>
              <div label={I18n.t("upload")}>
                <div className="pb-4">
                  <FileUpload
                    imageError={imageError}
                    insertFiles={insertFiles}
                  />
                </div>
              </div>
              <div label={<UnsplashTab />} className="unsplash-tab">
                <UnsplashTabContent
                  unsplashResults={unsplashResults}
                  searchUnsplash={searchUnsplash}
                  addUnsplash={addUnsplashImage}
                />
              </div>
              <div label={I18n.t("image_upload.embed")}>
                <EmbedUpload
                  imageError={imageError}
                  embedUrl={embedUrl}
                  onChangeEmbedUrl={e => setEmbedUrl(e.target.value)}
                  addEmbed={addEmbed}
                />
              </div>
            </Tabs>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

ImagePickerModalV2.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
};
