import type { MouseEventHandler } from "react";
import { NodeViewWrapper } from "@tiptap/react";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";

interface LinkPreviewProps {
  attachment: any;
  isEditable: boolean;
  onDelete: MouseEventHandler<HTMLButtonElement>;
}

export function LinkPreview({
  attachment,
  isEditable,
  onDelete,
}: LinkPreviewProps) {
  return (
    <NodeViewWrapper as="div" id="file-wrapper">
      <div className="group relative inline-block pe-4">
        <a href={attachment.url} download target="_blank" rel="noreferrer">
          {attachment.filename}
        </a>
        {isEditable && (
          <button
            type="button"
            className="bg-secondary border-primary text-dark absolute right-0 top-0 flex -translate-y-1/2 translate-x-1/2 scale-50 cursor-pointer items-center justify-center rounded-full border opacity-0 transition-all duration-200 group-hover:scale-100 group-hover:opacity-100"
            onClick={onDelete}
            aria-label={t("delete")}
          >
            <Icon type="close" />
          </button>
        )}
      </div>
    </NodeViewWrapper>
  );
}
