import { useRef } from "react";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { AddLinkFormBody } from "./AddLinkForm";
import { AddLinkHeader } from "./AddLinkHeader";
import { RemoveLinkButton } from "./RemoveLinkButton";
import { VALIDATION_SCHEMA } from "./validationSchema";

export interface AddTipTapLinkModalProps {
  editor: any;
  isAdminUsageContext?: boolean;
}

export const AddTipTapLinkModalComponent = ({
  editor,
  isAdminUsageContext = false,
}: AddTipTapLinkModalProps) => {
  const drawer = useModal();
  const isEditable = editor.isActive("link");
  const inputRef = useRef(null);

  const previousURL = editor.getAttributes("link")?.href;

  const handleRemoveLink = () => {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    void drawer.hide();
  };

  const handleOnSubmit = ({ url }: { url: string }) => {
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
    void drawer.hide();
  };

  return (
    <Modal
      isOpen={drawer.visible}
      onClose={drawer.hide}
      initialFocus={inputRef}
    >
      <Modal.Overlay />
      <Modal.Content>
        <AddLinkHeader isEditable={isEditable} onClose={drawer.hide} />
        <Form
          onSubmit={handleOnSubmit}
          defaultValues={{ url: previousURL }}
          validationSchema={VALIDATION_SCHEMA}
          autoComplete="off"
        >
          <AddLinkFormBody
            inputRef={inputRef}
            submitButtonVariant={isAdminUsageContext ? "circle" : "primary"}
          >
            <RemoveLinkButton
              isEditable={isEditable}
              onClick={handleRemoveLink}
            />
          </AddLinkFormBody>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

const AddTipTapLinkModal = ModalManager.create(
  ({ editor, isAdminUsageContext }: AddTipTapLinkModalProps) => (
    <AddTipTapLinkModalComponent
      editor={editor}
      isAdminUsageContext={isAdminUsageContext}
    />
  ),
);

export const useAddTipTapLinkModal = () => useModal(AddTipTapLinkModal);
