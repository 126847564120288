import { t } from "@/i18n-js/instance";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";

export interface FullscreenButtonProps {
  onClick: () => void;
}

const label = t("live_streams.room.button_tooltips.fullscreen");

export const FullscreenButton = ({ onClick }: FullscreenButtonProps) => (
  <TippyV2 content={label} interactive={false}>
    <IconButton
      name="fullscreen"
      variant="secondary"
      onClick={onClick}
      ariaLabel={label}
    />
  </TippyV2>
);
