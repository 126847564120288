import type { ReactNode, RefObject } from "react";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Modal } from "@circle-react-uikit/ModalV2";
import { LinkInput } from "../../Form/LinkInput";

export function AddLinkFormBody({
  inputRef,
  submitButtonVariant = "primary",
  children,
}: {
  inputRef: RefObject<HTMLInputElement>;
  submitButtonVariant?: "primary" | "circle";
  children: ReactNode;
}) {
  return (
    <>
      <Modal.Body>
        <Form.Item
          hideDescription
          hideBorder
          fullWidth
          name="url"
          label={t("tiptap.extensions.link.link")}
        >
          <LinkInput name="url" ref={inputRef} />
        </Form.Item>
      </Modal.Body>
      <Modal.Footer className="px-5 md:px-8">
        <Button full type="submit" variant={submitButtonVariant}>
          {t("tiptap.extensions.link.save")}
        </Button>

        {children}
      </Modal.Footer>
    </>
  );
}
