import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react/components/shared/Icon";
import { usePlanUpgradeModal } from "@circle-react-shared/uikit/TipTapBlockEditor/hooks/usePlanUpgradeModal";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useShowCopilot } from "../hooks/useShowCopilot";

export const AiCopilotButton = ({ editor }) => {
  const { openUpgradeModal } = usePlanUpgradeModal();
  const { shouldShowPlanUpgradeModal } = useShowCopilot();

  const addNode = () => {
    const state = editor.state;
    const endPos = state.selection.$to.after();
    const from = state.selection.$from.pos;
    const to = state.selection.$to.pos;

    editor.chain().focus().setHighlight({ color: "#B3D8FF" }).run();
    editor.commands.insertContentAt(endPos, {
      type: "aicopilot",
      attrs: {
        placeholder: t("tiptap.extensions.ai_copilot.input.edit_placeholder"),
        range: { from, to },
      },
    });
  };

  const handleClick = () => {
    if (shouldShowPlanUpgradeModal) {
      openUpgradeModal();
    } else {
      addNode();
    }
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className="text-dark hover:bg-secondary rounded-md p-1.5 pr-2 transition-colors"
    >
      <span className="flex items-center gap-1.5">
        <Icon type="16-ask-ai-gradient" size={16} />
        <Typography.LabelSm
          weight="medium"
          color="from-ai-start to-ai-end bg-gradient-to-r bg-clip-text text-transparent"
        >
          {t("tiptap.bubble_menu.ai_copilot")}
        </Typography.LabelSm>
      </span>
    </button>
  );
};
