import { useRef } from "react";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { EmbedForm } from "@circle-react-shared/uikit/TipTapBlockEditor/EmbedExtension/EmbedForm";
import { Modal } from "@circle-react-uikit/ModalV2";

export const EmbedModalComponent = ({
  editor,
  range,
  addToLocalSgidToObjectMap,
  provider,
}: any) => {
  const drawer = useModal();
  const inputRef = useRef(null);

  return (
    <Modal
      isOpen={drawer.visible}
      onClose={drawer.remove}
      initialFocus={inputRef}
    >
      <Modal.Overlay />
      <Modal.Content size="lg" shouldCloseOnOutsideClick>
        <Modal.Header>
          <Modal.Title size="sm">
            {provider
              ? t("tiptap.extensions.embed.add_provider", {
                  provider,
                })
              : t("tiptap.extensions.embed.add")}
          </Modal.Title>
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={drawer.remove} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          <EmbedForm
            inputRef={inputRef}
            addToLocalSgidToObjectMap={addToLocalSgidToObjectMap}
            editor={editor}
            range={range}
            provider={provider}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

const EmbedModal = ModalManager.create(
  ({ editor, range, addToLocalSgidToObjectMap, provider }: any) => (
    <EmbedModalComponent
      editor={editor}
      range={range}
      provider={provider}
      addToLocalSgidToObjectMap={addToLocalSgidToObjectMap}
    />
  ),
);

export const useEmbedModal = () => useModal(EmbedModal);
