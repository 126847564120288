import { Table } from "@circle-react-shared/uikit/TableV2";
import { ContentTableViewRows } from "./ContentTableViewRows";

export const ContentTableView = () => {
  const content: Array<[string, string[]]> = [
    ["1", ["2", "3", "4"]],
    ["5", ["6", "7"]],
    ["8", ["9", "10", "11"]],
  ];
  return (
    <Table className="skeleton-loader skeleton-loader--admin-member-edit-modal-access-community-member-access-content-table w-min">
      <Table.TableBody>
        {content.map(([header, items]) => (
          <ContentTableViewRows key={header} header={header} items={items} />
        ))}
      </Table.TableBody>
    </Table>
  );
};
