import { toLocaleString } from "@circle-react/helpers/number";

interface CounterProps {
  children: number;
  maxAmount?: false | number;
}

const MAX_AMOUNT = 10000;

export const numberFormatter = (
  amount = 0,
  maxAmount: false | number | undefined = MAX_AMOUNT,
) =>
  maxAmount && amount >= maxAmount
    ? `${toLocaleString(MAX_AMOUNT)}+`
    : toLocaleString(amount);

export const Counter = ({ children, maxAmount }: CounterProps) => {
  if (typeof children !== "number") return null;
  const formatted = numberFormatter(children, maxAmount);

  return <span className="ml-2 font-normal">{formatted}</span>;
};
