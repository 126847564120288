import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";

export const ButtonWithDisabled = ({
  type = "button",
  classNames = "",
  children,
  submitting = false,
  submittingText = t("submitting"),
  isDisabled = false,
  ...rest
}) => (
  <Button
    type={type}
    disabled={submitting || isDisabled}
    {...rest}
    className={classNames}
  >
    {submitting && submittingText}
    {!submitting && children}
  </Button>
);

ButtonWithDisabled.propTypes = {
  ...Button.propTypes,
  submitting: PropTypes.bool,
  submittingText: PropTypes.string,
};
