import { useBlockEditorContext } from "..";
import type { Editor } from "@tiptap/react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { ProgressiveImage } from "@circle-react-shared/ProgressiveImage";
import { AlignmentPicker } from "./AlignmentPicker";
import { EmailImageToolbar } from "./EmailImage/EmailImageToolbar";

interface ImageContainerProps {
  node: any;
  editor: Editor;
  attachmentMeta: any;
  onClickOpenAttachmentGallery: () => void;
  updateAttributes: (attrs: any) => void;
  deleteNode: () => void;
}

export const ImageContainer = ({
  node,
  editor,
  attachmentMeta,
  onClickOpenAttachmentGallery,
  updateAttributes,
  deleteNode,
}: ImageContainerProps) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const { isWithinEmailEditor } = useBlockEditorContext() ?? {};
  const isProgressiveImageLoadingOnPostsEnabled =
    currentCommunitySettings?.progressive_image_loading_on_post_space_enabled;
  const isEditable = editor.isEditable;

  const shouldShowNormalToolbar = isEditable && !isWithinEmailEditor;
  /* Older emails would have images in old format. Show these images, but with the email toolbar. */
  const shouldShowEmailToolbar = isEditable && isWithinEmailEditor;

  let widthRatio = 1;
  if (node.attrs.width_ratio) {
    widthRatio = node.attrs.width_ratio;
  } else if (node.attrs.width) {
    // Temporary failsafe in case data is not properly migrated.
    widthRatio = node.attrs.width === "100%" ? 1 : 0.5;
  }

  const width = `${widthRatio * 100}%`;

  return (
    <div
      style={{ width }}
      className={classNames("group relative", {
        "mx-auto": node.attrs.alignment === "center",
      })}
    >
      <button
        type="button"
        className="w-full"
        onClick={onClickOpenAttachmentGallery}
        aria-label={t("tiptap.extensions.image_preview.view_attachment")}
      >
        {isProgressiveImageLoadingOnPostsEnabled ? (
          <ProgressiveImage
            alt=""
            src={attachmentMeta.url}
            className={classNames("w-full", {
              "cursor-zoom-in": !isEditable,
            })}
            srcVariants={attachmentMeta?.image_variants}
          />
        ) : (
          <img
            loading="lazy"
            alt=""
            className={classNames("w-full", {
              "cursor-zoom-in": !isEditable,
            })}
            src={attachmentMeta.url}
          />
        )}
      </button>
      {shouldShowNormalToolbar && (
        <AlignmentPicker node={node} updateAttributes={updateAttributes} />
      )}
      {shouldShowEmailToolbar && (
        <EmailImageToolbar
          editor={editor}
          node={node}
          updateAttributes={updateAttributes}
          deleteNode={deleteNode}
        />
      )}
    </div>
  );
};
