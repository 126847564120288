import { useMemo } from "react";
import type { ReactNode } from "react";
import classNames from "classnames";
import { useTrialBanner } from "@/react/components/Layout/TrialBanner/useTrialBanner";
import { useDmsV3Enabled } from "@circle-react/hooks/useDmsV3Enabled";
import { useTailwindLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { HEADER_HEIGHT, HEADER_HEIGHT_SM } from "./index";

interface ContentProps {
  children: ReactNode;
  heightGap?: number;
  noPadding?: boolean;
  isBelowHeader?: boolean;
  isCourseSpace?: boolean;
  className?: string;
  id?: string;
}

export const Content = ({
  children,
  heightGap,
  noPadding = false,
  isBelowHeader = false,
  isCourseSpace = false,
  className = "",
  id = "",
}: ContentProps) => {
  const { isTrialBannerExists } = useTrialBanner();
  const { isMessagingModalOpen } = useDmsV3Enabled();
  const isLgScreen = useTailwindLgScreenMediaQuery();

  const headerHeight = useMemo(() => {
    let height = HEADER_HEIGHT;

    if (heightGap) {
      height = heightGap;
    } else if (isBelowHeader) {
      height = HEADER_HEIGHT + HEADER_HEIGHT_SM;
    }

    if (isMessagingModalOpen) {
      height = height + 40;
    } else {
      if (isTrialBannerExists && !isCourseSpace) {
        height = height + 48;
      }
      if (isLgScreen) {
        height = height + 48;
      }
    }

    return height;
  }, [
    heightGap,
    isBelowHeader,
    isTrialBannerExists,
    isMessagingModalOpen,
    isLgScreen,
    isCourseSpace,
  ]);

  return (
    <div
      id={id}
      className={classNames(
        "scrollbar-hide overflow-auto",
        {
          "px-6 py-4": !noPadding,
        },
        className,
      )}
      style={{
        height: `calc(100dvh - ${headerHeight}px)`,
      }}
    >
      {children}
    </div>
  );
};
