import type { ElementType, ReactNode } from "react";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Typography } from "@circle-react-uikit/Typography";

const sizeClasses = {
  sm: "px-2 py-1.5",
  md: "px-3 py-2",
} as const;

interface Option<T extends string = string> {
  value: T;
  label: ReactNode;
  ariaLabel?: string;
}

export interface SelectRadioGroupProps<T extends string = string> {
  value: T;
  onChange: (value: T) => void;
  options: Option<T>[];
  size?: keyof typeof sizeClasses;
  className?: string;
  checkedClassName?: string;
  optionClassName?: string;
}

export const SelectRadioGroup = <T extends string = string>({
  value,
  onChange,
  options,
  size = "md",
  className = "",
  checkedClassName = "bg-tertiary",
  optionClassName = "",
}: SelectRadioGroupProps<T>) => {
  const sizeClass = sizeClasses[size] ?? sizeClasses.md;

  return (
    <RadioGroup<ElementType, T> value={value} onChange={onChange}>
      <div className={classNames("flex items-center gap-6", className)}>
        {options.map(({ label, value }) => (
          <RadioGroup.Option
            key={value}
            value={value}
            className={({ checked }) =>
              classNames(
                "hover:bg-tertiary",
                "focus:bg-tertiary focus:ring-primary focus:ring-2 focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2",
                "flex cursor-pointer select-none rounded-lg leading-none transition-colors",
                sizeClass,
                {
                  [checkedClassName]: checked,
                },
                optionClassName,
              )
            }
          >
            {({ checked }) => (
              <RadioGroup.Label
                as="span"
                className={classNames("flex whitespace-nowrap", {
                  "text-light": !checked,
                  "text-dark": checked,
                })}
                {...(checked && { "aria-checked": checked })}
              >
                {typeof label === "string" ? (
                  <Typography.LabelSm weight={checked ? "medium" : "normal"}>
                    {label}
                  </Typography.LabelSm>
                ) : (
                  label
                )}
              </RadioGroup.Label>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
